@mixin scrollbar {
  width: 11px;
  height: 11px;
  border-left: 1px solid $color-gray-extended-2;
}

@mixin scrollbar-btn {
  display: none;
  width: 0;
  height: 0;
}

@mixin scrollbar-corner {
  background-color: transparent;
}

@mixin scrollbar-thumb {
  height: 11px;
  min-height: 40px;
  border: 3px solid transparent;
  border-radius: 7px;
  background-clip: padding-box;
  background-color: $color-gray-extended-2;
  box-shadow: inset -1px -1px 0 $color-gray-extended-2, inset 1px 1px 0 $color-gray-extended-2;
}

/* main style */

@mixin main-scroll-style {
  ::-webkit-scrollbar {
    @include scrollbar;
  }

  ::-webkit-scrollbar-button {
    @include scrollbar-btn;
  }

  ::-webkit-scrollbar-corner {
    @include scrollbar-corner;
  }

  ::-webkit-scrollbar-thumb {
    @include scrollbar-thumb;
  }
}

/*  design for different styles: dark, light, selectbox */
// light main design
@mixin light-main-scroll-style {
  @include main-scroll-style();
}

// light selectbox design
@mixin light-selectbox-scroll-style {
  @include main-scroll-style();

  ::-webkit-scrollbar-thumb {
    background-color: $color-gray-extended-4;
    box-shadow: inset -1px -1px 0 $color-gray-extended-4, inset 1px 1px 0 $color-gray-extended-4;
  }
}

// dark main design
@mixin dark-main-scroll-style {
  @include main-scroll-style();

  ::-webkit-scrollbar {
    border-left: 1px solid $color-gray-extended-5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-gray-extended-4;
    box-shadow: inset -1px -1px 0 $color-gray-extended-4, inset 1px 1px 0 $color-gray-extended-4;
  }
}

// dark selectbox
@mixin dark-selectbox-scroll-style {
  @include main-scroll-style();

  ::-webkit-scrollbar {
    border-left: 1px solid $color-gray-extended-5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-gray-extended-5;
    box-shadow: inset -1px -1px 0 $color-gray-extended-5, inset 1px 1px 0 $color-gray-extended-5;
  }
}

/* change scrollbar in general */

::-webkit-scrollbar {
  @include scrollbar;
}

::-webkit-scrollbar-button {
  @include scrollbar-btn;
}

::-webkit-scrollbar-corner {
  @include scrollbar-corner;
}

::-webkit-scrollbar-thumb {
  @include scrollbar-thumb;
}

.selectbox {
  &.light {
    @include light-selectbox-scroll-style();
  }

  &.dark {
    @include dark-selectbox-scroll-style();

    ::-webkit-scrollbar {
      background: $color-gray-extended-6;
    }

    .dark {
      overflow: hidden;

      .ReactVirtualized__List.ReactVirtualized__Grid {
        @include dark-selectbox-scroll-style();
        border-right: 1px solid $color-gray-extended-5;
        border-bottom: 1px solid $color-gray-extended-5;
      }
    }
  }
}

.table {
  @include dark-main-scroll-style();

  &--light {
    @include light-main-scroll-style();
  }

  .groups-list-details__alerts-section {
    &::-webkit-scrollbar {
      border-left: 1px solid transparent;
    }
  }
}

.slide-in {
  @include dark-main-scroll-style();

  .selectbox {
    .dark {
      @include dark-selectbox-scroll-style();

      ::-webkit-scrollbar {
        background: $color-gray-extended-7;
      }
    }
  }

  .search {
    @include dark-selectbox-scroll-style();

    .ReactVirtualized__Grid.ReactVirtualized__List {
      border-right: 1px solid $color-gray-extended-5;
      border-bottom: 1px solid $color-gray-extended-5;
    }

    ::-webkit-scrollbar {
      background-color: $color-gray-extended-7;
    }
  }
}

.lights-ids {
  .slide-in {
    ::-webkit-scrollbar {
      border-left: 1px solid $color-gray-extended-6;
    }
  }
}

.dropdown {
  .selectbox {
    &.dark {
      ::-webkit-scrollbar {
        background: $color-gray-extended-7;
      }
    }
  }
}

.group-actions {
  .dropdown {
    .group-actions__container-group-list {
      .ReactVirtualized__List.ReactVirtualized__Grid {
        border-top: 1px solid $color-gray-extended-5;
        border-right: 1px solid $color-gray-extended-5;
        border-bottom: 1px solid $color-gray-extended-5;

        &::-webkit-scrollbar {
          border-left: 0;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-gray-extended-5;
          box-shadow: inset -1px -1px 0 $color-gray-extended-5, inset 1px 1px 0 $color-gray-extended-5;
        }
      }
    }
  }
}

.groups-filter__lighting-tab-content {
  .ReactVirtualized__List.ReactVirtualized__Grid {
    border-top: 1px solid $color-gray-extended-5;

    &::-webkit-scrollbar {
      border-left: 0;
      background: $color-gray-extended-7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-extended-5;
      box-shadow: inset -1px -1px 0 $color-gray-extended-5, inset 1px 1px 0 $color-gray-extended-5;
    }
  }
}

.toolbar__subtoolbar {
  .open {
    &::-webkit-scrollbar {
      border-left: 0;
      background: $color-gray-extended-7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-extended-5;
      box-shadow: inset -1px -1px 0 $color-gray-extended-5, inset 1px 1px 0 $color-gray-extended-5;
    }
  }
}

.site-selector__list-container {
  .ReactVirtualized__List.ReactVirtualized__Grid {
    &::-webkit-scrollbar {
      border-left: 1px solid transparent;
    }
  }
}

.recently-viewed__dropdown {
  &::-webkit-scrollbar {
    border-left: 0;
    background: $color-primary-black;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-extended-5;
    box-shadow: inset -1px -1px 0 $color-gray-extended-5, inset 1px 1px 0 $color-gray-extended-5;
  }
}

.accounts__partners {
  .partner__customer-list.customer-list {
    .ReactVirtualized__Grid.ReactVirtualized__List {
      padding-right: 3px;
    }
  }

  &::-webkit-scrollbar {
    border-left: 1px solid transparent;
  }

  .ReactVirtualized__Grid.ReactVirtualized__List {

    &::-webkit-scrollbar {
      border-left: 1px solid transparent;
    }
  }

  .selectbox {
    .ReactVirtualized__Grid__innerScrollContainer {
      padding-right: 0;
      border-right: 1px solid $color-gray-extended-2;
    }
  }
}

.tabletoolbar.users {

  .ReactVirtualized__Grid.ReactVirtualized__List {
    &::-webkit-scrollbar {
      border-left: 1px solid $color-gray-extended-5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-extended-5;
      box-shadow: inset -1px -1px 0 $color-gray-extended-5, inset 1px 1px 0 $color-gray-extended-5;
    }
  }
}

.dropdown-lighting-group-list {
  .ReactVirtualized__Grid.ReactVirtualized__List {
    border-top: 1px solid $color-gray-extended-5;
    border-right: 1px solid $color-gray-extended-5;
    border-bottom: 1px solid $color-gray-extended-5;

    &::-webkit-scrollbar {
      border-left: 0;
      background: $color-gray-extended-7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-extended-5;
      box-shadow: inset -1px -1px 0 $color-gray-extended-5, inset 1px 1px 0 $color-gray-extended-5;
    }
  }
}

.alarms-multi {
  .ReactVirtualized__Grid__innerScrollContainer {
    background-color: $color-gray-extended-6;

    &::-webkit-scrollbar {
      border-left: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-extended-5;
      box-shadow: inset -1px -1px 0 $color-gray-extended-5, inset 1px 1px 0 $color-gray-extended-5;
    }
  }
}

.assigned-groups__list {

  .ReactVirtualized__Grid.ReactVirtualized__List {

    &::-webkit-scrollbar {
      border-left: 1px solid $color-gray-extended-2;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-extended-4;
      box-shadow: inset -1px -1px 0 $color-gray-extended-4, inset 1px 1px 0 $color-gray-extended-4;
    }
  }
}
