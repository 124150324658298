.auto-open-selectbox {

  .label {
    margin-bottom: 5px;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 16px;
  }

  &__list {
    width: 240px;
    border: 1px solid $color-primary-black;
    overflow-y: auto;

    &-element {
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $color-gray-extended-2;
      cursor: pointer;

      .btn {
        display: flex;
        width: 100%;
        padding: 0 0 0 10px;
        border: 0;
        border-left: 4px solid transparent;
        outline: 0;
        background: transparent;
        font-family: $font-family-roman;
        font-size: 14px;
        line-height: 39px;

        &:hover {
          border-left: 4px solid $color-primary-black;
        }
      }

      &.selected {
        .btn {
          border-left: 4px solid $color-primary-red;
        }
      }

      .checkbox {
        display: flex;
        justify-content: center;
        margin: auto;
        margin-left: 10px;
        padding: 0;
        border: 0;
        outline: 0;
        background: transparent;

        .check {
          align-self: center;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          padding: 2px;
          border: 1px solid $color-primary-black;
        }
      }

      &-title {
        font-family: $font-family-roman;
        font-size: 14px;
        line-height: 39px;
        cursor: pointer;
      }
    }
  }
}
