.config {
  &__content {
    display: flex;
    position: relative;
    flex-grow: 1;
    background-color: $screen-background-1;

    .list-container {
      display: block;
      height: 100%;
    }

    .toolbar-container {
      display: flex;
      position: relative;
      align-items: center;

      &--left {
        justify-content: flex-start;
        min-height: 15px;
        max-height: 15px;
      }

      &--right {
        justify-content: flex-end;
        min-height: 48px;
        max-height: 48px;
        margin-bottom: 10px;
      }

      &__datasize-text {
        margin-right: 6px;
        font-family: $font-family-roman;
        font-size: 13px;

        &--bold {
          font-family: $font-family-bold;
        }
      }

      .btn-textlink {
        border: 0;
      }

      .btn-small {
        svg {
          width: 10px;
        }
      }
    }

    &-toolbar-button {
      height: 30px;
    }

    &-title {
      margin-right: auto;
      color: $color-primary-black;
      /* stylelint-disable font-weight-notation */
      font: normal normal bold 24px/22px $font-family-bold;
    }

    .firmware-list {
      &__elements {
        display: flex;
        align-items: center;
        height: 60px;
        margin: 5px 5px 10px;
        background-color: $color-primary-white;
        color: $color-primary-black;
        font-size: 12px;
        box-shadow: 0 2px 5px $color-list-shadow-box;

        .info-cell {
          display: flex;
          align-items: center;
          height: 100%;
          margin-left: 30px;
          border-right: 1px solid $screen-background-1;

          &--release {
            width: 20.3%;
            margin-left: 20px;
          }

          &--date {
            width: 16.2%;
          }

          &--model {
            width: 21.2%;
          }

          &--id {
            width: 17.3%;
          }

          &--size {
            width: 14.3%;
          }

          &--btn {
            width: 10.7%;
            margin-left: 20px;
          }

          &-label {
            font: normal normal normal 12px/14px $font-family-bold;
            line-height: 2;
          }

          &-data {
            font: normal normal normal 14px/16px $font-family-text-roman;
          }

          &-data--large {
            font: normal normal bold 20px/20px $font-family-bold;
          }

          .btn {
            font: normal normal normal 12px/14px $font-family-bold;
          }
        }

        .info-cell:last-child {
          border-right: 0;
        }
      }
    }

    .modal:not(.wizard-modal) {
      padding: 30px;
      white-space: nowrap;

      .modal__content {
        display: flex;
        flex-direction: column;
        color: $color-primary-black;

        &-has-table {
          display: block;
          height: 100%;
        }
      }

      .modal__footer {
        margin-top: auto;
      }

      .modal__title {
        line-height: 28px;
      }

      .radio-buttons {
        display: flex;
        flex-direction: column;

        &__element {
          margin-bottom: 10px;

          &-label {
            font: normal normal normal 14px/16px $font-family-text-roman;
            letter-spacing: 0;
          }
        }
      }

      .textbox {
        margin-top: 20px;

        label {
          line-height: 17px;
        }
      }

      .selection-label {
        margin-bottom: 10px;
        font-family: $font-family-medium;
        font-size: 14px;
        line-height: 17px;
      }

      .selectbox {
        margin-top: 10px;

        .selectbox__label {
          line-height: 17px;
        }

        ul {
          position: absolute;
          top: 63px;
          border-top: 2px solid $color-primary-black;
          background-color: $color-primary-white;
        }
      }

      .submit-info {
        display: flex;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 20px;
        stroke: $color-secondary-blue-40;
        color: $color-gray-extended-7;
        font: normal normal normal 12px/14px $font-family-roman;
        letter-spacing: 0;
        white-space: normal;

        .icon {
          width: 34px;
          margin-right: 6px;
        }
      }
    }

    &.audit-trail {
      .page-content {
        margin: 20px 26px 30px 30px;
      }

      .admin__sidebar-title-section {
        border-bottom: 1px solid $color-gray-extended-7;
      }
    }
  }
}
