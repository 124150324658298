.schedule-footer {
  display: flex;
  position: relative;
  height: 70px;
  background-color: $color-primary-black;
  z-index: map-get($z-index, table-footer);

  &__datepicker {
    display: flex;

    > button {
      display: flex;
      align-items: center;
      width: 220px;
      height: 70px;
      margin: 0;
      padding-left: 30px;
      border: 0;
      background-color: $color-gray-extended-7;

      > svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        fill: $color-primary-white;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
          color: $color-secondary-yellow;
          font-family: $font-family-medium;
          font-size: 14px;
        }
      }
    }

    .react-datepicker-container {
      position: absolute;
      bottom: 57px;
      margin: 0;

      .react-datepicker {
        border-color: $color-gray-extended-5;

        &:after,
        &:before {
          position: absolute;
          top: 100%;
          left: 42px;
          border: solid transparent;
          content: '';
          pointer-events: none;
        }

        &:after {
          margin-left: -5px;
          border-width: 5px;
          border-top-color: $color-gray-extended-7;
        }

        &:before {
          margin-left: -6px;
          border-width: 6px;
          border-top-color: $color-gray-extended-5;
        }
      }
    }
  }

  &__timeline {
    display: flex;
    width: 100%;

    &--play {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      margin: 0;
      border: 0;
      background-color: $color-primary-black;
    }

    &--bar {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 20px 10px 0;

      .timeline-playbar {
        height: 5px;
        padding: 0;
        border: 0;
        background-color: $color-schedule-playbar-bg;
        z-index: map-get($z-index, table-footer);

        div {
          height: 5px;
          background-color: $color-primary-white;
        }

        svg {
          display: block;
          position: relative;
          bottom: 10px;
        }
      }

      .timeline-gradientbar {
        position: relative;
        height: 35px;
        border-bottom: 1px solid $color-gray-extended-4;
        background-color: $color-primary-black;

        svg {
          position: absolute;
          bottom: 0;
          z-index: 10;
        }

        .timeline-hour-bar {
          position: absolute;
          bottom: 0;
          width: 2px;
          height: 6px;
          background-color: $color-gray-extended-2;
        }

        .timeline-sunrise-sunset-bar {
          position: absolute;
          width: 0;
          height: 100%;
          border: 1px dashed $color-gray-extended-4;
        }

        .tooltip {
          position: absolute;
          height: 100%;

          .top {
            transform: translate(-50%, -58px);
          }
        }

        &--hover {
          width: 2px;
          height: 100%;
          background-color: $color-gray-extended-4;
        }
      }

      .timeline-clock {
        display: flex;
        position: relative;
        margin-top: 2px;

        div {
          display: flex;
          position: absolute;
          justify-content: center;
          width: 24px;
          color: $color-gray-extended-2;
          font-size: 10px;

          &:first-of-type {
            left: 0;
          }

          &:last-of-type {
            right: 0;
            justify-content: start;
          }
        }
      }
    }
  }
}
