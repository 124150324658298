.no-data {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-primary-white;
  z-index: map-get($z-index, map);

  &__border {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% - 60px);
    height: calc(100% - 70px);
    margin-top: 30px;
    margin-right: 30px;
    margin-bottom: 40px;
    margin-left: 30px;
    border: 1px solid $color-gray-extended-2;

    &-image {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      height: 100%;

      .nodata-image {
        max-width: 440px;
        height: 100%;
        max-height: 309px;
        background-image: url('../../img/icons/nodata.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 60%;
      margin-top: 20px;
      padding-right: 160px;
      padding-left: 170px;
      color: $color-primary-black;

      &--title {
        font-family: $font-family-bold;
        font-size: 30px;
        line-height: 32px;
      }

      &--subtitle {
        margin-top: 50px;
        font-family: $font-family-roman;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
