.textbox {
  display: flex;
  position: relative;
  flex-direction: column;

  &__icon {
    position: absolute;
    top: 10px;
    right: 0;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  > svg {
    position: absolute;
    top: calc(50% - 7px);
    left: 10px;
    width: 15px;
    height: 15px;
  }

  label {
    display: inline-block;
    margin: 0 0 5px;
    color: $color-primary-black;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;

    span {
      &.textbox-required {
        margin: 0;
        color: $color-primary-red;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &__input {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border-top: 1px solid $color-gray-extended-2;
    border-right: 1px solid $color-gray-extended-2;
    border-bottom: 1px solid $color-primary-black;
    border-left: 1px solid $color-gray-extended-2;
    background-color: $color-primary-white;
    color: $color-primary-black;
    font-family: $font-family-roman;

    &--icon {
      padding: 0 30px;
    }

    &::placeholder {
      color: $color-gray-extended-2;
      font-family: $font-family-roman;
    }

    &:disabled {
      border: 1px solid $color-gray-extended-1;
      background-color: $color-gray-extended-1;
      cursor: not-allowed;
    }
  }

  &.small {
    .textbox__icon {
      top: 5px;
    }

    .textbox__input {
      height: 30px;
    }
  }

  textarea {
    padding-top: 12px;
  }

  &.dark {
    label {
      color: $color-gray-extended-2;
    }

    input,
    textarea,
    .textbox__input {
      border-top: 1px solid $color-gray-extended-5;
      border-right: 1px solid $color-gray-extended-5;
      border-bottom: 1px solid $color-gray-extended-4;
      border-left: 1px solid $color-gray-extended-5;
      background-color: $color-gray-extended-7;
      color: $color-gray-extended-2;

      &--icon {
        padding: 0 30px;
      }
    }
  }

  &.darken {

    label {
      color: $color-primary-white;
    }

    input,
    textarea {
      border: 1px solid $color-gray-extended-5;
      border-bottom: 1px solid $color-gray-extended-2;
      background: $color-gray-extended-7;
      color: $color-gray-extended-4;

      &::placeholder {
        color: $color-gray-extended-4;
      }
    }
  }

  &.error {
    input,
    textarea,
    .textbox__input {
      border-top: 1px solid $color-secondary-orange;
      border-right: 1px solid $color-secondary-orange;
      border-bottom: 4px solid $color-secondary-orange;
      border-left: 1px solid $color-secondary-orange;
    }

    span {
      margin-top: 1px;
      margin-bottom: 4px;
      color: $color-secondary-orange;
      font-size: 10px;
      white-space: pre-wrap;
    }
  }

  .close {
    position: absolute;
    top: calc(50% - 5px);
    right: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  &.long {
    margin-bottom: 10px;
    border-bottom: 1px solid $color-gray-extended-2;

    label {
      display: block;
      margin-bottom: 6px;
      color: $color-gray-extended-2;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }

    input,
    textarea {
      font-family: $font-family-roman;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &.horizontal {
    display: grid;
    width: 100%;

    label {
      display: flex;
      align-self: center;
      grid-column: 1;
    }

    span {
      margin-top: 4px;
      margin-bottom: 0;
      grid-column: 2;
    }

    input,
    textarea {
      grid-column: 2;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid $color-gray-extended-5;
    border-bottom: 1px solid $color-gray-extended-4;
    color: $color-gray-extended-2;
    box-shadow: 0 0 0 1000px $color-gray-extended-7 inset;
    -webkit-text-fill-color: $color-gray-extended-2;
    -webkit-box-shadow: 0 0 0 1000px $color-gray-extended-7 inset;
  }
}
