.multi-select-box {
  .ReactVirtualized__List {
    border: 1px solid $color-primary-black;
    background-color: $color-primary-white;

    .filter-list-row {
      display: flex;
      align-items: center;
      width: 100%;
      color: $color-primary-black;
      font-size: 14px;

      &__checkbox {
        margin-right: 10px;
        margin-left: 10px;
      }

      &__name {
        flex-grow: 1;
      }

      &__node-list {
        color: $color-gray-extended-4;
      }
    }

    .filter-list-row + .filter-list-row {
      border-top: 1px solid $color-gray-extended-2;
    }
  }

  & .search {
    &-container {
      display: flex;
      justify-content: center;
      padding: 7px 10px;
      border: 1px solid $color-primary-black;
      border-bottom: 1px solid $color-gray-extended-4;
      background-color: $color-primary-white;
    }

    &-input {
      width: 100%;
      padding: 10px;
      border: 1px solid $color-gray-extended-2;
      border-bottom: 1px solid $color-primary-black;
      background-color: $color-primary-white;
      color: $color-primary-black;
      font-size: 14px;
    }

    &-icon {
      position: absolute;
      top: 17px;
      right: 20px;
      width: 20px;
      height: 20px;
      fill: $color-primary-black;
    }
  }
}
