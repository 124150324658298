
.system-alert {
  table > tbody > tr > td:first-child {
    text-align: right;
    width: 120px;
    min-height: 40px;
    padding: 0px 6px 12px 6px;
    font-weight: bold;
    vertical-align: top;
  }
  input[type='textarea'] {
    width: 800px;
  }
  div.textbox {
    margin-bottom: 0px;
  }
  button {
    margin-left: 20px;
    margin-bottom: 12px;
    font-size:14px;
  }
  .content {
    max-height: 450px;
    overflow: auto;
  }
}
