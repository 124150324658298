.alarms-list {
  &__first-cell {
    display: flex;
    align-items: center;
    min-width: 160px;
    height: 100%;
    font-family: $font-family-medium;

    &--wide {
      width: 100%;
    }

    svg {
      min-width: 12px;
      margin-right: 12px;
      margin-left: 12px;
      margin-left: auto;
    }

    .checkbox {
      min-width: 20px;

      .checkbox__button {
        svg {
          margin: 0;
        }
      }
    }
  }

  &__container {
    width: 330px;
  }

  &__alerts-cell {
    display: flex;
    align-items: center;
    width: 30%;
    min-width: 110px;
    height: 100%;
    border-right: 1px solid $color-gray-extended-5;

    .groups-list__element {
      width: 33%;
      margin: 0;
      padding-left: 10px;
      font-family: $font-family-bold;
    }

    :nth-child(2) {
      border-right: 1px solid $color-gray-extended-5;
      border-left: 1px solid $color-gray-extended-5;
    }
  }

  &__schedules-cell {
    display: flex;
    align-items: center;
    width: 22%;
    min-width: 80px;
    height: 100%;
    margin-right: 10px;
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    > svg {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
  }

  &__action-cell {
    display: flex;
    align-items: center;
    width: 6%;
    min-width: 45px;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    border-left: 1px solid $color-gray-extended-5;
    background: transparent;

    svg {
      width: 100%;
    }
  }

  &__elements {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: $color-gray-extended-7;
    color: $color-primary-white;
    font-family: $font-family-bold;
    font-size: 14px;

    .checkbox {
      margin: 0 2px 0 0;
    }

    svg {
      fill: $color-primary-white;
    }

    &.open {
      margin-bottom: 0;
      background: $color-gray-extended-7;
    }
  }

  &__element {
    display: flex;

    &__name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      width: 18px;
      height: 11px;
      fill: $color-primary-white;
      stroke: $color-primary-white;

      &.minor {
        fill: $color-alarms-minor;
        stroke: $color-alarms-minor;
      }

      &.major {
        fill: $color-alarms-major;
        stroke: $color-alarms-major;
      }

      &.critical {
        fill: $color-secondary-orange;
        stroke: $color-secondary-orange;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90px;
      margin-left: auto;

      button {
        display: flex;
        align-items: center;
        width: 30px;
        background: 0 none;
        border: 0 none;
      }

      svg {
        cursor: pointer;
      }
    }

    &__title {
      align-self: center;
      margin-left: 34px;
      color: $color-gray-extended-3;
      font-family: $font-family-medium;
      font-size: 10px;
      line-height: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__length {
      margin-left: 5px;
      color: $color-secondary-yellow;
      font-size: 10px;
      line-height: 12px;
    }

    svg {
      margin: 0 12px;
    }

    &--ok-nodes {
      color: $color-secondary-green;
    }

    &--alert-nodes {
      color: $color-secondary-orange;
    }

    &--schedule {
      margin: 0 0 0 10px;
      padding: 0;
      border: 0;
      background-color: transparent;
      color: $color-secondary-blue-80;
      font-family: $font-family-medium;
      font-size: 10px;
      text-decoration: underline;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &--open {
      transform: rotate(180deg);
    }
  }

  &-details {
    display: flex;
    width: 100%;
    height: 160px;
    margin: 0 10px;
    padding: 10px 36px;
    border-right: 1px solid $color-gray-extended-5;
    border-bottom: 1px solid $color-gray-extended-5;
    border-left: 1px solid $color-gray-extended-5;
    background-color: $color-gray-extended-7;
    color: $color-gray-extended-2;

    &__nodes {
      width: 40%;
      padding-right: 20px;
      border-right: 1px solid $color-gray-extended-8;
    }

    &__nodes-title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      color: $color-primary-white;
      font-family: $font-family-medium;
      font-size: 14px;

      &-value {
        margin-right: 5px;
      }

      svg {
        width: 14px;
        height: 14px;
        margin-right: 5px;
        fill: $color-gray-extended-4;
      }
    }

    &__status-list {
      padding-top: 10px;
      border-top: 1px solid $color-gray-extended-5;
      border-bottom: 1px solid $color-gray-extended-5;
      font-size: 10px;
    }

    &__nodes-status {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: $color-gray-extended-2;

      svg {
        width: 12px;
        height: 12px;
      }

      &-ok {
        display: flex;
        align-items: center;
        margin-right: 5px;
        color: $color-secondary-green;
        font-family: $font-family-bold;

        svg {
          margin-right: 5px;
          fill: $color-secondary-green;
        }
      }

      &-warning {
        display: flex;
        align-items: center;
        margin-right: 5px;
        color: $color-secondary-orange;
        font-family: $font-family-bold;

        svg {
          margin-right: 5px;
        }
      }

      &-schedule {
        display: flex;
        align-items: center;
        margin-right: 5px;
        color: $color-primary-white;
        font-family: $font-family-bold;

        svg {
          margin-right: 5px;
          fill: $color-primary-white;
        }
      }
    }

    &__group-desc {
      margin: 10px 0 20px;
      font-size: 10px;

      &-title {
        margin-right: 5px;
        color: $color-primary-white;
        font-family: $font-family-bold;
      }
    }

    &__alerts {
      width: 60%;
      margin-left: 20px;

      &-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: $color-primary-white;
        font-family: $font-family-medium;
        font-size: 14px;

        &-value {
          margin: 0 5px;
          font-family: $font-family-bold;
        }

        svg {
          fill: $color-secondary-orange;
        }
      }

      &-section {
        display: flex;
        height: 125px;
        overflow: scroll;

        &--total-alerts {
          width: 100%;
        }
      }

      &-types {
        display: block;
        width: 115px;
        font-size: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &-all {
        display: flex;
        justify-content: space-between;
      }

      &-value {
        display: flex;
        width: 100%;
      }

      &-obj {
        display: flex;
        justify-content: space-between;
        margin: 0 5px 5px 0;
      }

      &-numbers {
        display: flex;
        flex-direction: column;
        align-items: self-end;
        min-width: 25px;
        margin-right: 5px;
        color: $color-primary-white;
        font-family: $font-family-bold;
        font-size: 10px;
      }

      &-count {
        width: 100%;
        height: 8px;
        margin-bottom: 5px;
        background-color: $color-gray-extended-6;
      }
    }
  }
}
