.tabletoolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $commissioning-toolbar-height;
  border: $commissioning-toolbar-border;
  background: $color-gray-extended-6;
  color: $color-primary-white;

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    font-family: $font-family-bold;
    font-size: 20px;

    &.width-subtitle {
      height: 55px;
    }
  }

  &.btn-container {

    .tabletoolbar__children {
      display: flex;
      height: 100%;
      margin-right: 20px;
      border-left: 1px solid $color-gray-extended-5;

      button {
        margin: auto;

        &:first-child {
          margin-right: 10px;
          margin-left: 20px;
        }
      }
    }
  }

  .audit-trail-from-lights {
    position: absolute;
    left: 360px;
    top: 38px;
  }

  .audit-trail-tabletoolbar {
    display: flex;
    position: relative;
    height: 100%;
    cursor: pointer;

    .tabletoolbar-subtitle {
      margin: auto;
      padding-right: 20px;
    }

    .tabletoolbar__children {
      display: flex;
      width: 280px;
      padding-right: 20px;
      padding-left: 20px;
      border-left: 1px solid $color-gray-extended-5;

      &-content {
        display: flex;
        width: 100%;

        svg {
          margin-top: auto;
          margin-bottom: auto;
        }

        &--date {
          display: grid;
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 15px;
          color: $color-gray-extended-2;
          font-family: $font-family-roman;
          font-size: 12px;
          line-height: 14px;

          &-startdate,
          &-enddate {
            margin-left: 5px;
            color: $color-secondary-yellow;
            font-family: $font-family-medium;
          }

          &-startdate {
            grid-column: 2;
            grid-row: 1;
          }
        }
      }

      .dropdown {
        position: absolute;
        top: 53px;
        right: -1px;
        width: 281px;
        padding-bottom: 30px;
        color: $color-gray-extended-2;
        z-index: 5;

        &__footer {
          justify-content: flex-end;

          .btn-secondary {
            margin: 0;
          }

          .btn-primary {
            margin-right: 10px;
          }
        }

        .react-datepicker__label--above {
          font-family: $font-family-medium;
        }
      }
    }
  }

  &.accounts,
  &.users {
    margin: 0;

    .tabletoolbar__children {
      display: flex;
      height: 100%;
      padding: 0 20px;
      border-left: 1px solid $color-gray-extended-5;

      .textbox{
        margin: auto;

        input {
          width: 200px;
          padding-left: 0;
          border: 0;
          border-bottom: 1px solid $color-primary-white;
          background: transparent;
          color: $color-primary-white;
          font-family: $font-family-medium;

          &::placeholder {
            color: $color-primary-white;
            font-family: $font-family-medium;
          }
        }

        svg {
          fill: $color-primary-white;
        }
      }

      &-btn {
        display: flex;
        height: 100%;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid $color-gray-extended-5;

        .btn {
          margin: auto;
          border-color: $color-primary-white;
        }
      }
    }
  }

  &.sitemanager {
    .tabletoolbar__children {
      display: flex;
      height: 100%;
      padding: 0 20px;
      border-left: 1px solid $color-gray-extended-5;

      .btn {
        margin: auto;
        border-color: $color-primary-white;
      }
    }
  }

  &.users {

    .tabletoolbar__children {
      &.no-border {
        border: 0;
      }
    }

    .tabletoolbar__children-btn {
      margin: 0;
    }

    .search-partner-and-account {
      display: flex;

      .search {
        display: flex;
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid $color-gray-extended-5;

        .search__input,
        .search__input::placeholder {
          display: flex;
          width: 200px;
          height: 23px;
          margin: auto;
          border-width: 1px;
          color: $color-primary-white;
          font-family: $font-family-medium;
        }

        svg {
          stroke: $color-primary-white;
          top: 50%;
          right: 20px;
          fill: #000;
          transform: translate(0, -50%);
        }

        .ReactVirtualized__Grid {
          position: absolute;
          top: 38px;
          width: 178px;
          border: 1px solid $color-gray-extended-5;
          background: $color-gray-extended-7;
          z-index: 5;

          .search-partner--list {
            display: flex;
            align-items: center;
            border-top: 1px solid $color-gray-extended-5;
            color: $color-primary-black;
            line-height: 40px;
            cursor: pointer;

            span {
              color: $color-gray-extended-2;
              font-size: 14px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }

        &.last {
          margin: 0;
          border-right: 0;
        }
      }
    }

    .user-action {
      display: flex;
      padding-left: 20px;
      border-left: 1px solid $color-gray-extended-5;
    }

    .selectbox {
      margin-right: 20px;

      &__label {
        margin: 0;
      }

      &__header {
        display: flex;
        height: 23px;
        margin: auto;
        padding-left: 0;
        border: 0;
        border-bottom: 1px solid $color-primary-white;
        background: transparent;
        color: $color-primary-white;
        font-family: $font-family-medium;

        svg {
          right: 0;
          stroke: $color-primary-white;
        }
      }

      .light {
        position: absolute;
        top: calc(50% + (23px / 2));
        left: 0;
        border: 1px solid $color-gray-extended-5;
        background: $color-gray-extended-7;

        li {
          border-bottom: 1px solid $color-gray-extended-5;

          div {
            color: $color-gray-extended-2;
          }
        }
      }
    }
  }
}
