.alert-activity-reports {
  .btn-textlink {
    border: 0;
  }

  .readonly {
    > .tooltip {
      margin-right: 60px;
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: auto;

    > .tooltip {
      width: 14px;
      min-width: 14px;
      height: 14px;
      margin-right: 15px;
      cursor: pointer;

      svg {
        position: absolute;
        width: 14px;
        height: 14px;
      }
    }
  }

  .alert-report-item {
    display: flex;
    height: 65px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-gray-extended-2;

    &__icon {
      margin-right: 5px;
    }

    &__title {
      display: flex;
      flex-direction: row;

      &--version {
        margin-right: 5px;
        font-family: $font-family-medium;
      }
    }

    &__created {
      margin-top: 5px;
      font-size: 12px;
    }

    &__links {
      margin-top: 5px;

      a, button {
        margin-right: 25px;
        border: 0;
        font-family: $font-family-roman;
      }
    }
  }

  &-tabletoolbar {
    .tabletoolbar__children-content {
      height: 55px;

      .toolbar-tabs__links-icons {
        border-bottom: 4px solid transparent;
        border-left: 1px solid $color-gray-extended-5;

        &:hover {
          border-bottom: 4px solid $color-primary-red;
        }

        .toolbar-tabs__links-icons-header {
          >div {
            height: 100%;
            padding: 0;

            .request-report {
              display: flex;
              align-items: center;
              height: 100%;
              padding: 5px 15px;

              svg {
                width: 24px;
                height: 24px;
              }

              >div {
                display: flex;
                flex-direction: column;
                padding: 0 0 0 15px;

                span:last-of-type {
                  font-family: $font-family-medium;
                }
              }
            }
          }
        }
      }
    }
  }

  .radio-buttons-label {
    margin-bottom: 10px;
    margin-top: 20px;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 16px;

    &.first {
      margin-top: 0;
    }
  }

  .selectbox {
    ul {
      position: absolute;
      top: 100%;
      background: $color-primary-white;
    }
  }

  .wizard-node-selector {
    height: 470px;

    .table {
      height: 350px;

      .table-list {
        &.table__header {
          display: flex;

          .table__header-cell {
            height: 50px;

            &.expand-btn {
              width: 122px;
              padding-left: 10px;

              .btn-textlink {
                font-family: $font-family-roman;
              }
            }
          }

          .header-name {
            flex-grow: 1;
          }

          .header-count {
            width: 80px;
          }
        }

        &.table__data {
          height: 300px;

          .node-list-item {
            display: flex;
            align-items: center;
            height: 100%;
            margin-bottom: 3px;
            border-top: 1px solid $color-gray-extended-2;
            border-bottom: 1px solid $color-gray-extended-2;

            &--caret {
              cursor: pointer;

              &.open {
                transform: rotate(180deg);
              }
            }

            .checkbox--light {
              margin-left: 28px;
            }

            &--caret,
            &--name {
              margin-left: 30px;
            }

            &--name,
            &--count {
              font-family: $font-family-bold;
            }

            &--name {
              flex-grow: 1;
            }

            &--count {
              width: 80px;

              &.has-scroll {
                width: 70px;
              }
            }
          }
        }
      }

      .expanded-table-list {
        &.table__header {
          display: flex;
          height: 30px;
          border: 0;
          background-color: $color-primary-white;
          font-family: $font-family-roman;
          font-size: 10px;
          color: $color-gray-extended-4;

          .header-nodeid {
            flex-grow: 1;
            margin-left: 120px;
          }

          .header-group-fixture {
            width: 300px;
          }

          .textbox {
            height: 30px;
            width: 140px;
          }
        }

        &.table__data {
          height: 200px;
          border: 0;

          .expanded-node-list-item {
            display: flex;
            align-items: center;
            height: 100%;

            .checkbox--light {
              margin-left: 70px;
            }

            &--name {
              flex-grow: 1;
              margin-left: 30px;
            }

            &--count {
              width: 300px;

              &.has-scroll {
                width: 290px;
              }
            }
          }
        }
      }
    }

    .selectbox {
      width: 220px;
      margin-bottom: 20px;
      margin-top: 5px;
    }

    &-footer {
      padding: 10px 0;
      font-family: $font-family-medium;
    }
  }

  .wizard-filter-selector {
    width: 220px;

    .report-schedule {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-top: 5px;

      >span {
        margin-left: 15px;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .react-datepicker-container {
        margin-right: 15px;
        margin-bottom: 0;

        .react-datepicker__label--above {
          font-family: $font-family-medium;
        }

        .react-datepicker-wrapper {
          margin: 0;
        }

        .react-datepicker-popper {
          position: absolute !important;
          top: 44px !important;
        }
      }
    }

    >.filter-multi-select-box,
    >.selectbox,
    .selectbox-status .selectbox {
      width: 220px;
    }

    >.filter-multi-select-box {
      margin-top: 5px;
    }

    .selectbox-status {
      margin-top: 20px;
    }
  }

  .wizard-output-selector {
    &--title {
      margin-bottom: 15px;
      font-family: $font-family-bold;
      font-size: 18px;
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
      background-color: $color-gray-extended-1;
      border-top: 1px solid $color-gray-extended-2;

      .checkbox--light {
        margin-left: 45px;
      }

      button {
        margin-right: 15px;
        font-family: $font-family-roman;
        font-size: 14px;
      }
    }

    &--content {
      border-top: 1px solid $color-gray-extended-5;
      border-bottom: 2px solid $color-gray-extended-5;

      .wizard-draggable-column {
        background-color: $color-primary-white;
        border-top: 0;
      }
    }
  }

  .wizard-review {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .textbox {
      width: 300px;
    }

    .report-summary-title {
      margin-top: 5px;
      margin-bottom: 25px;
      font-family: $font-family-bold;
      font-size: 18px;
    }

    .report-summary-content {
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      line-height: 16px;

      >div {
        margin-bottom: 15px;
      }


      &--left {
        width: 160px;
        font-family: $font-family-bold;
      }

      &--right {
        width: 430px;

        &.alert-type, &.recipients {
          display: flex;
          flex-wrap: wrap;
        }

        .chip-item {
          display: inline;
          padding: 2px 8px;
          margin-right: 8px;
          margin-bottom: 5px;
          background-color: $color-gray-extended-2;
          border-radius: 8px;
        }
      }
    }
  }
}

.wizard-draggable-column {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: $color-gray-extended-1;
  border-top: 1px solid $color-gray-extended-2;
  border-bottom: 1px solid $color-gray-extended-2;
  font-family: $font-family-roman;
  font-size: 14px;
  user-select: none;

  >svg {
    width: 15px;
    margin-left: 15px;
    fill: $color-gray-extended-3;
  }

  .checkbox--light {
    margin-left: 15px;
  }

  .column-title {
    margin-left: 10px;
  }
}
