.select-byfile__success,
.select-byfile__mix,
.select-byfile__warning {
  padding: 30px;
  border-top: 5px solid $color-secondary-green;
  color: $color-primary-black;
  font-size: 14px;
  line-height: 16px;

  .modal__content {
    flex-direction: column;
    overflow: hidden;
  }

  .modal__title {
    margin-bottom: 25px;
  }

  .select-byfile__success-content,
  .select-byfile__warning-content {
    display: flex;
    align-items: center;

    .bold {
      font-family: $font-family-medium;
    }

    svg {
      margin-right: 5px;
      fill: $color-secondary-green;
    }
  }

  &-comment {
    margin-top: 10px;
  }

  .select-byfile__warning-content {
    border-bottom: 0;

    svg {
      fill: $color-secondary-yellow;
    }
  }
}

.select-byfile__warning,
.select-byfile__mix {
  border-top-color: $color-secondary-yellow;
}

.select-byfile__mix {
  .select-byfile__warning-content {
    svg {
      align-self: start;
      width: 24px;
    }
  }

  &--id-list {
    display: flex;
    flex-direction: column;
    height: 170px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 1px solid $color-gray-extended-2;
    border-bottom: 1px solid $color-primary-black;
    color: $color-primary-black;
    font-size: 12px;
    line-height: 20px;
    cursor: default;
    overflow: auto;

    span {
      display: block;
    }
  }

  .btn-secondary {
    align-self: flex-end;
    width: 140px;
    margin-right: 0;
  }

  .select-byfile__success-content {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $color-gray-extended-2;
  }
}
