/* stylelint-disable max-nesting-depth */
.group-actions,
.multi-node-modals {
  white-space: nowrap;

  &__container {
    width: 410px;

    &-group {
      padding: 0 20px;

      .arrow {
        display: flex;
        align-self: center;
        height: 6px;
        margin: 0;
        stroke: $color-primary-white;
        fill: $color-primary-white;
      }

      &-title {
        display: flex;
        width: 100%;
        padding: 20px 0;
        border: 0;
        outline: 0;
        background: transparent;
        color: $color-primary-white;
        font-family: $font-family-bold;
        font-size: 18px;
        line-height: 20px;
        cursor: pointer;

        div {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        svg {
          width: 18px;
          height: 18px;
          margin-right: 15px;
          fill: $color-gray-extended-4;
        }
      }

      &-list {
        display: none;
        padding-bottom: 10px;

        &-element {
          padding: 10px 0 10px 31px;
          cursor: pointer;

          &:hover {
            .group-actions__container-group-list-btn {
              color: $color-gray-extended-2;
            }
          }
        }

        .no-top-mg > .group-actions__container-group-list-element {
          padding-top: 0;
        }

        &-btn {
          padding: 0;
          border: 0;
          outline: 0;
          background: transparent;
          color: $color-primary-white;
          font-family: $font-family-medium;
          font-size: 14px;
          line-height: 16px;
          cursor: pointer;
        }
      }

      &.open {
        background: $color-gray-extended-8;

        .group-actions__container-group-list {
          display: block;
        }

        .arrow {
          transform: rotate(180deg);
        }

        .group-actions__container-group-title {
          svg {
            fill: $color-primary-white;
          }
        }

        .group-actions__container-group-subtitle {
          position: relative;
          top: -18px;
          left: 37px;
        }
      }
    }
  }

  .modal {
    padding: 30px;

    &__content {
      display: flex;
      flex-direction: column;
      color: $color-primary-black;

      .subtitle {
        margin-bottom: 20px;
        padding-bottom: 14px;
        border-bottom: 1px solid $color-gray-extended-2;
        font-family: $font-family-roman;
        font-size: 14px;
        line-height: 16px;
        white-space: break-spaces;

        span {
          font-family: $font-family-medium;
        }
      }

      .content {
        display: flex;

        &-selected-nodes {
          display: flex;
          flex-direction: column;
          width: 124px;
          margin-right: 30px;

          &-title {
            display: flex;
            justify-content: center;
            font-family: $font-family-medium;
            font-size: 18px;
            line-height: 18px;
          }

          &-big {
            align-self: center;
            font-family: $font-family-bold;
            font-size: 32px;
            line-height: 38px;
          }

          span:last-child {
            align-self: center;
            margin-top: 3px;
            font-family: $font-family-medium;
            font-size: 12px;
            line-height: 14px;
          }
        }

        &-text {
          align-self: center;
          font-size: 14px;
          line-height: 18px;
          white-space: normal;

          span {
            font-family: $font-family-medium;
          }

          &-highlighted {
            font-family: $font-family-medium;
            font-size: 16px;
          }
        }

        &__container {

          &-info {
            color: $color-gray-extended-4;
            font-size: 12px;
            line-height: 14px;
          }

          &-form {
            margin-top: 15px;
          }
        }

        &.update {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid $screen-background-1;

          .content-selected-nodes {
            width: 84px;
          }

          .content-text {
            display: flex;
            align-items: center;

            .arrow {
              margin-right: 30px;

              svg {
                width: 24px;
                height: 24px;
              }
            }

            &-info {
              span {
                display: block;

                &:first-child {
                  margin-bottom: 6px;
                  font-family: $font-family-medium;
                  font-size: 14px;
                  line-height: 16px;
                }

                &:last-child {
                  font-family: $font-family-roman;
                  font-size: 18px;
                  line-height: 18px;
                }
              }
            }
          }
        }

        &__confirm {
          display: flex;

          .arrow {
            display: flex;
            align-items: center;

            svg {
              width: 24px;
              height: 24px;
              fill: $color-primary-black;
            }
          }

          &-data {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 30px;

            &-title {
              margin-bottom: 6px;
              font-family: $font-family-medium;
              font-size: 14px;
              line-height: 16px;
            }

            &-value {
              font-size: 18px;
              line-height: 18px;
            }
          }
        }

        &.assign-fixture {
          .content__confirm-data-value {
            width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .remove-org {

    .content-text {
      width: 229px;
      margin: 0;
    }

    .modal__footer {
      margin-top: 10px;
    }
  }
}

.group-actions {
  .dropdown {
    padding: 0;

    &__content {
      border: 1px solid $color-gray-extended-5;
      box-shadow: 0 5px 10px $color-dropdown-shadow-box;
    }
  }

  .no-padding-modal {

    .modal {
      padding: 0;
    }
  }

  .force-modal-padding {
    padding: 0px 30px;
    line-height: 20px;
  }

  .group-actions__modal {
    .modal__title {
      margin-top: 30px;
      margin-right: 30px;
      margin-left: 30px;
    }

    .content__container {
      &-form {
        width: 100%;
        margin-top: 0;
        margin-bottom: 25px;
        padding-right: 30px;
      }

      &-nodes-size {
        display: flex;
        padding-left: 30px;
        font-family: $font-family-bold;
        font-size: 14px;

        span {
          align-self: center;
          font-size: 32px;
        }
      }

      &-nodes-selected {
        display: flex;
        flex-direction: column;
        margin-right: 25px;
      }
    }

    .selectbox,
    .textbox {
      padding-left: 30px;
    }

    .modal__footer {
      margin: 0;
      padding-top: 20px;
      padding-right: 30px;
      padding-bottom: 30px;
      background: $color-gray-extended-1;
    }

    &-container {
      .content__container {
        display: flex;
        width: 100%;
      }

      .horizontal {
        margin-bottom: 13px;
        padding-right: 30px;
      }
    }

    .selected-info {
      padding: 20px 30px 0;
      border-top: 1px solid $color-gray-extended-2;
      background: $color-gray-extended-1;

      &__title {
        padding-bottom: 5px;
        font-family: $font-family-medium;
        font-size: 14px;
        line-height: 16px;
      }

      &__content {
        display: flex;

        &-nodes {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 120px;
          font-family: $font-family-bold;
          font-size: 32px;

          span {
            align-self: center;
          }

          &-title {
            margin-bottom: 20px;
            font-family: $font-family-medium;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

      &.title-top {
        .selected-info {
          &__content {
            &-nodes {
              justify-content: start;
            }
          }
        }
      }

      .double-targetgroups {
        display: block;
        margin-left: 20px;
        padding-left: 15px;
        border-left: 1px solid $color-gray-extended-2;

        &__content {
          display: flex;

          &:first-child {
            margin-bottom: 15px;
          }

          .target-group-info {
            width: 300px;

            &__title {
              display: flex;
              flex-direction: column;
            }
          }

          .add-selected__content-target  {

            .icon {
              fill: $color-gray-extended-2;
            }
          }

          &-title {
            display: flex;
            flex-direction: column;
            width: 75px;
            color: $color-primary-black;
            font-family: $font-family-medium;
            font-size: 16px;
            line-height: 18px;

            &-number {
              margin-top: 4px;
              color: $color-gray-extended-2;
              font-family: $font-family-bold;
              font-size: 24px;
              line-height: 24px;
            }
          }

          &.default {
            .target-group-info__title {
              width: 190px;
              white-space: normal;
            }

            .target-group-info-org-update {
              display: flex;
              height: 70px;
              margin-left: 25px;
              color: $color-gray-extended-2;
              font-size: 14px;
              line-height: 16px;
              white-space: normal;

              &-content {
                display: flex;
                align-self: center;

                span {
                  display: flex;
                  align-self: center;
                  width: 220px;
                }

                &-icon {
                  width: 18px;
                  height: 18px;
                  margin-right: 6px;
                  fill: $color-gray-extended-2;
                }
              }
            }
          }
        }

        .icon {
          fill: $color-gray-extended-2;
        }
      }

      &.has-selected-node {

        .target-group,
        .double-targetgroups {
          &__content {
            &-title {
              &-number {
                color: $color-primary-black;
              }
            }
          }

          .target-group {
            &-info {
              border: 1px solid $color-secondary-blue-40;
              background: $color-secondary-blue-5;

              &__title {
                display: flex;
                flex-direction: column;

                &-name {

                  &.title {
                    color: $color-primary-black;
                    font-family: $font-family-medium;
                  }

                  &.groupname {
                    color: $color-secondary-blue;
                    font-family: $font-family-medium;
                  }
                }
              }

              svg {
                fill: $color-secondary-blue-40;
              }
            }
          }

          .default {

            .target-group-info {
              border-color: $color-gray-extended-3;
              background: $screen-background-1;

              &__icon {
                fill: $color-gray-extended-5;
              }

              &__title-name {
                color: $color-gray-extended-5;
              }
            }
          }

          .icon {
            fill: $color-primary-black;
          }
        }

        .double-targetgroups__content {
          &.default {
            .target-group-info-org-update-content {
              color: $color-gray-extended-5;

              &-icon {
                fill: $color-secondary-orange;
              }
            }
          }
        }
      }
    }
  }

  .org-group {
    display: grid;
    grid-template-columns: 120px 39px auto;

    &__title {

      &-name {
        padding-bottom: 10px;
        font-family: $font-family-medium;
        font-size: 14px;
        line-height: 16px;
        grid-column: 1;
      }

      &-nodes {
        font-family: $font-family-bold;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        grid-column: 1;
        grid-row: 2;
      }
    }

    .icon {
      width: 24px;
      height: 38px;
      margin-left: 15px;
      grid-column: 2;
      grid-row: 2;
    }

    &__content {
      margin-left: 15px;
      grid-column: 3;
      grid-row: 2;

      .auto-open-selectbox {

        .label {
          display: flex;
          align-items: center;
          height: 38px;

          svg {
            height: 24px;
            margin-right: 5px;
          }

          &__title {
            font-size: 18px;
            line-height: 20px;
          }

          &__comment {
            margin-right: 0;
            margin-left: auto;
            font-family: $font-family-roman;
            font-size: 12px;
            line-height: 14px;

            &-bold {
              font-family: $font-family-bold;
            }
          }
        }

        ul {
          width: 100%;
        }
      }

      &-info {
        display: flex;
        justify-content: baseline;
        margin-top: 10px;

        &-icon {
          width: 18px;
          height: 18px;
          fill: $color-secondary-blue;
        }

        span {
          align-self: center;
          margin-left: 6px;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}

.group-actions__modal {
  &.create-org-group {
    .modal__footer {
      background: transparent;
    }
  }
}

.multi-node-modals.driver-level {
  .modal__content {
    .content-text {
      align-self: flex-start;
    }
  }
}

.target-group,
.double-targetgroups {
  display: flex;
  margin-left: 7px;

  &-info {
    display: flex;
    width: 350px;
    height: 70px;
    margin-left: 10px;
    border: 1px solid $color-gray-extended-2;

    svg {
      align-self: center;
      width: 40px;
      height: 40px;
      margin-left: 15px;
      fill: $color-gray-extended-2;
    }

    &__title {
      align-self: center;
      margin-left: 15px;
      color: $color-gray-extended-2;

      &-name {
        font-size: 16px;
        line-height: 18px;

        .bold {
          font-family: $font-family-medium;
        }
      }
    }
  }

  .icon {
    align-self: center;
    width: 24px;
    height: 24px;
  }
}

.submit-info {
  display: none;
  margin-top: 25px;

  &--visible {
    display: flex;
  }

  &-info,
  &-warning {
    display: flex;
    color: $color-gray-extended-7;
    font-family: $font-family-roman;
    font-size: 12px;
    line-height: 14px;
    white-space: normal;

    .icon {
      width: 18px;
      margin-right: 6px;
    }
  }

  &-info {
    width: 258px;
    margin-right: 25px;
    fill: $color-secondary-blue;
  }

  &-warning {
    width: 227px;
    fill: $color-secondary-orange;

    &-bold {
      font-family: $font-family-medium;
    }
  }
}
