.site-modal {
  display: flex;
  width: 100%;

  &--info {
    display: flex;
    margin-bottom: 20px;
    font-size: 14px;

    &icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      border-radius: 14px;
      background-color: $color-primary-red;
      color: $color-primary-white;
      font-family: $font-family-bold;
      font-size: 10px;
    }
  }

  &--left {
    width: 210px;
    margin-right: 30px;
  }

  &--right {
    width: 200px;
    margin-left: 30px;
  }

  &--map {
    flex-grow: 1;

    &.map-error {
      position: relative;
    }

    &.leaflet-control-geosearch {
      top: 200px;
      width: 240px;
    }

    .map-error-text {
      display: flex;
      position: absolute;
      bottom: 1px;
      left: 1px;
      width: calc(100% - 2px);
      padding: 5px;
      padding-left: 3px;
      background: $color-secondary-orange;
      color: $color-primary-white;
      font-size: 12px;

      svg {
        height: 12px;
        margin-right: 3px;
        fill: $color-primary-white;
      }
    }
  }

  &--section {
    margin-top: 5px;
    margin-bottom: 10px;
    font-family: $font-family-medium;
    font-size: 18px;
    line-height: 20px;
  }

  &--state-zip {
    display: flex;
  }

  &--state {
    width: 120px;
    margin-right: 10px;
  }

  &--zip {
    width: 70px;
  }

  .info-box {
    font-size: 14px;
    line-height: 16px;

    &--title {
      margin-top: 30px;
      margin-bottom: 5px;
      font-family: $font-family-medium;
    }

    &--text {
      margin-bottom: 5px;
    }
  }

  .selectbox {
    margin-bottom: 15px;

    ul {
      position: absolute;
      top: 63px;
      background-color: $color-primary-white;
    }
  }
}
