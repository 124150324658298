.multi-node-modals {
  .notification-bar,
  .notification-bar--info {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }

  .dropdown__primary-button {
    height: auto;
    color: $color-secondary-blue-80;
  }

  .manual-override {
    .modal {
      .modal__content {
        .content-selected-nodes {
          width: auto;
          min-width: 88px;
        }
      }
    }
  }

  .edit-note {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid $color-gray-extended-2;

    &__title {
      display: block;
      margin-bottom: 25px;
      color: $color-gray-extended-7;
      font-family: $font-family-bold;
      font-size: 24px;
      line-height: 26px;
    }

    &__info {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      font-size: 10px;
    }

    .textbox {
      margin-bottom: 10px;
    }
  }
}
