.analysis-page {
  .analysis-sidebar-site-name {
    color:#FFBC3D;
    font-family: "Neue Haas Grotesk W01 Display 65 Medium";
    font-size: 14px;
    position: relative;
    top: -14px;
    left: 21px;
  }
  .analysis-sidebar-subheading {
    font-size: 14px;
    font-weight: bold;
    margin: 18px 8px 4px 18px;
  }
  .admin__sidebar-item {
    cursor: pointer;
  }
  .modify-report {
    font-family: "Neue Haas Grotesk W01 Display 65 Medium";
    position: relative;
    top: 17px;
    right: 18px;
  }
  .create-edit-report-definition {
    .selectbox-wrapper {
      width: 200px;
      align-self: start;
    }
    .modal__content {
      flex-direction: column;
    }
    .modal__secondary-button {
      margin-right: 60px;
      background-color:#000;
      color:#FFF;
    }
    .report-definition-when {
      margin-bottom: 12px;
    }
    .report-definition {
      .radio-buttons {
        flex-direction: column;
      }
      .radio-buttons__element-circle {
        width: 20px !important;
      }
      &__report-name {
        width: 100%;
        display:flex;
        flex-direction: row;
        margin-bottom: 16px;
        >div:first-child {
          flex: 15 1 auto;
          padding-right: 24px;
        }
        >div:nth-child(2) {
          flex: 1 1 auto;
          position: relative;
          top: 32px;
        }
      }
      &__modal-content {
        display: flex;
        flex-direction: row;
        .col1 {
          flex: 1 1 auto;
          height: 360px;
          overflow-y: auto;
          scrollbar-gutter: stable;
          padding-right: 12px;
          border-right:2px solid #333;
          .report-definition-frequency {
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            .textbox {
              margin-right: 12px;
            }
          }
        }
        .col2 {
          height: 360px;
          overflow-y: auto;
          scrollbar-gutter: stable;
          flex: 1 1 auto;
          border-right: 2px solid #333;
          padding-right: 12px;
          margin-left: 12px;
          max-width: 330px;
          >div:nth-child(2) {
            margin-top:12px;
          }
        }
        .col3 {
          height: 360px;
          overflow-y: auto;
          scrollbar-gutter: stable;
          margin-right: 12px;
          flex: 1 1 auto;
          margin-left: 12px;
        }
      }
    }
    .modal__title {
      margin-bottom: 20px;
    }
    .modal__content {
      height: 444px;
      overflow-y: auto;
    }
    .modal-divided {
      height: 440px;
    }
    .modal-divided__leftcontent {
      width: 280px;
      margin-right: 40px;
      .interval-fields {
        margin-top: 10px;
        div {
          display:inline-block;
          vertical-align: top;
        }
        div:first-child {
          width: 60px;
          margin-right: 12px;
        }
        div:nth-child(2) {
          position: relative;
          top: -33px;
        }
      }
    }
    .modal-divided__rightcontent {
      width: auto;
    }
    .report-definition-dimension-table {
      margin-top: 12px;
      height: 280px;
      width: 360px;
    }
    .report-definition-when {
      margin-top:0px;
    }
    .report-definitions-scope-message,
    .report-definitions-timerange-message {
      font-size: 16px;
      margin-top: 60px;
      text-align: center;
      line-height: 24px;
      .site-name {
        color:#FFBC3D;
        font-family: "Neue Haas Grotesk W01 Display 65 Medium";
        font-size: 14px;
      }
    }
    .date-period-selector__instruction {
      font-family: "Neue Haas Grotesk W01 Display 65 Medium";
      font-size: 14px;
      line-height: 16px;
    }
    .react-datepicker-container {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 0;

      .react-datepicker__label--above {
        font-family: $font-family-medium;
      }

      .react-datepicker-wrapper {
        margin: 0;
      }

      .react-datepicker-popper {
        position: absolute !important;
        top: 44px !important;
      }
    }
  }
  .status-dot {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;

    &.active, &.created {
      background-color: $color-secondary-green;
    }

    &.disabled {
      background-color: $color-secondary-yellow-map;
    }

    &.executed {
      background-color: transparent;
      border: 1px solid #666;
    }
  }
  .report-definition-status {
      text-transform: capitalize;
  }
  .activate-message {
    font-weight: bold !important;
    font-size: 16px;
    width: 90%;
    margin: 20px auto;
    border: 4px solid #CCC;
    padding: 10px;
    p {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.analysisdashboard-panel {
  height: 100%;
  .toolbar-tabs__container-title-main {
    font-size: 20px;
  }
  .toolbar-tabs__container-title {
    width: 350px;
  }
  .toolbar-tabs__container-tabset-container--selectbox {
    width: 250px;
  }
  .toolbar-tabs__container-tabset-container.last .toolbar-tabs__container-tabset-btn.active {
    background: black;
  }
  .selectbox {
    position: absolute;
    width: 250px;
  }
  .selectbox.light .selectbox__label {
    line-height: 70px;
    padding-left: 15px;
    margin-bottom: 10px;
    color: #D8DADA;
    font-family: "Neue Haas Grotesk W01 Display 55 Roman";
    font-size: 14px;
  }

  .selectbox.light .selectbox__header {
    width: 220px !important;
    color: #FFBC3D;
    font-family: "Neue Haas Grotesk W01 Display 65 Medium";
    height: 70px;
    background: transparent;
    margin-top: -70px;
    border: 0;
  }
  .selectbox__header span {
    padding-top: 10px;
  }
  .selectbox.light ul {
    border: 1px solid #535353;
    background: #000;
  }
  .selectbox.light ul div {
    width: 250px !important;
    max-width: 250px !important;
  }
  .selectbox.light ul li div {
    color: white;
  }
  .legend-node .checkbox--light__button {
    padding: 0 3px;
  }
}
