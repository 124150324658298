.timeline {
  position: relative;
  margin-right: 20px;

  &__y {
    display: flex;
    position: absolute;
    top: 0;
    left: -30px;
    flex-direction: column;
    width: 25px;
    color: #747676;
    font-family: $font-family-roman;
    font-size: 10px;
    line-height: 12px;
    white-space: nowrap;

    div:not(:first-child) {
      display: flex;
      align-self: flex-end;
    }
  }

  &__x {
    display: flex;
    margin-top: 10px;
    color: #747676;
    font-family: $font-family-roman;
    font-size: 10px;
    line-height: 12px;
  }

  &__uppertext {
    display: flex;
    height: 12px;
    margin-bottom: 10px;
    color: $color-primary-black;
    font-family: $font-family-roman;
    font-size: 10px;
    line-height: 12px;

    div {
      display: flex;
      justify-content: center;
    }
  }

  &__points {
    position: absolute;
    top: 22px;
    padding: 9px 10px 7px;
    border-top: 1px solid #747676;
    border-bottom: 2px solid $color-primary-black;

    > div {
      position: absolute;
      width: 20px;
      height: 20px;

      &.ghost-dimmingpoint {
        border: 1px solid $color-gray-extended-2;
        border-radius: 20px;
        background-color: $color-schedule-ghost-dimmingpoint-bg;
      }

      .spec-tooltip {
        width: 20px;
        height: 20px;

        &__element {
          display: none;
          top: -12px;

          &.tooltip-show {
            display: flex;
          }
        }

        &:hover {
          .spec-tooltip__element {
            display: flex;
          }
        }

        &__element-data {
          display: flex;

          > div {
            display: flex;
            flex-direction: column;

            .tooltip-title {
              margin-right: 5px;
              line-height: 16px;
            }

            .tooltip-value {
              font-family: $font-family-medium;
              line-height: 17px;
            }
          }
        }

        &__element-delete {
          display: flex;
          align-items: center;
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px solid $color-schedule-tooltip-separator;
          cursor: pointer;
        }
      }
    }
  }
}
