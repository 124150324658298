.chart-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px;

  .chart {
    width: 100%;
    height: 100%;

    &__nodata {
      color: $color-primary-black;

      &-element {
        padding: 10px;
        padding-bottom: 0;
      }
    }
  }

  .chart-legends {
    width: 300px;
    min-width: 300px;
    height: 100%;
    margin-left: 30px;
    padding-top: 20px;
    color: $color-primary-black;

    &__header {
      display: flex;
      justify-content: space-between;
      height: 40px;
      border-bottom: 1px solid $color-primary-black;

      &--title {
        font-family: $font-family-bold;
        font-size: 20px;
      }

      &--legends {
        > div {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 14px;

          > svg {
            margin-right: 5px;
          }
        }
      }
    }

    &__list {
      color: $color-primary-black;

      &--element {
        display: flex;
        max-width: 300px;
        border-bottom: 1px solid $screen-background-1;
        font-family: $font-family-roman;
        font-size: 12px;
        line-height: 14px;

        &:last-of-type {
          border-bottom: 1px solid $color-primary-black;
        }

        .legend-node {
          display: flex;
          align-items: center;
          min-width: 160px;
          height: 50px;

          > div {
            max-width: 120px;
            margin-left: 10px;

            > div {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .legend-node--id {
              font-family: $font-family-medium;
            }
          }
        }

        .legend-sensors {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 130px;
          margin-left: 10px;
          line-height: 18px;

          &--1,
          &--2 {
            display: flex;
            align-items: center;

            &-title {
              min-width: 110px;
              margin-left: 5px;
            }
          }
        }
      }
    }

    &__no-data {
      color: $color-primary-black;
    }
  }

  .selected {
    .chart-legends {
      &__list-container-element-circle,
      &__list-container-element-label,
      &__list-container-element-rectrot,
      &__list-container-element-color {
        opacity: .5;
      }
    }
  }
}

.chart-modal {
  .modal__content {
    height: 100%;
  }
}
