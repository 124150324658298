.Resizer {
  background: $color-gray-extended-8;
  background-clip: padding-box;
  z-index: map-get($z-index, resizer);
  box-sizing: border-box;

  &:before {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-8px, -50%);
    background-image: url('../../img/icons/default-grabber.svg');
    content: '';
    z-index: map-get($z-index, resizer);
  }

  &:hover {
    background-color: $color-gray-extended-4;

    &:before {
      width: 24px;
      height: 24px;
      transform: translate(-10px, -50%);
      background-image: url('../../img/icons/hover-grabber.svg');
    }
  }

  &.vertical {
    width: 5px;
    cursor: col-resize;
  }
}

.SplitPane {
  .Pane {
    z-index: 0;

  }

  &.collapsed {
    .Resizer {
      background-color: $color-gray-extended-5;

      &:before {
        width: 40px;
        height: 40px;
        transform: translate(-18px, -50%);
        background-image: url('../../img/icons/collapsed-panel-grabber.svg');
      }
    }
  }
}
