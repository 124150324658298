.dropdown {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border: 1px solid $color-gray-extended-5;
  background: $color-primary-black;
  color: $color-primary-white;

  &__title {
    font-family: $font-family-bold;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;

    &.space-bottom {
      margin-bottom: 15px;
    }
  }

  &__subtitle {
    margin-bottom: 15px;
    color: $color-gray-extended-3;
    font-size: 12px;
  }

  &__footer {
    display: flex;
    margin-top: 15px;

    .btn-textlink {
      margin-left: 15px;
    }
  }

  &.toolbar-action-dropdown {
    .btn-secondary {
      margin-right: 0;
    }

    .btn-primary {
      margin-right: 5px;
    }
  }
}
