.header {
  border-bottom: 1px solid $color-gray-extended-2;

  &__list {
    display: flex;
    height: $header-height;
    background: $color-primary-white;

    &-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 40px 0 30px;
      padding: 0;
      border: 0;
      background: $color-primary-white;
      color: $color-primary-black;

      img {
        padding: 0 10px 0 0;
      }

      &-dot {
        height: $dot-height;
        margin: $dot-height 10px 0 0;
      }
    }

    &-logo {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      svg {
        text {
          tspan {
            font-family: $font-family-bold;
          }
        }
      }

      span {
        height: 50%;
        font-family: $font-family-bold;
      }
    }

    &-item {
      display: flex;
      margin: 0 30px 0 0;
      color: $color-primary-black;

      &-link {
        display: flex;
        align-items: flex-end;
        height: 100%;
        padding: 0;
        border: 0;
        border-bottom: 5px solid transparent;
        background-color: $color-primary-white;
        color: $color-primary-black;

        svg {
          align-self: center;
          width: 12px;
          height: 14px;
          margin: 5px 5px 0 0;
          fill: $color-gray-extended-4;
        }

        &.active {
          border-bottom: 5px solid $color-primary-red;

          svg {
            fill: $color-primary-red;
          }
        }

        &:hover {
          border-bottom: 5px solid $color-primary-black;
        }

        span {
          margin-bottom: 17px;
          font-family: $font-family-medium;
          font-size: 14px;
        }

        &.disabled {
          border-color: transparent;
          color: $color-gray-extended-2;
          cursor: default;

          svg {
            fill: $color-gray-extended-4;
          }
        }
      }
    }
  }

  &__links {
    display: flex;
    font-size: 14px;

    .selectbox {
      width: 300px;
      border: 0;
      border-right: $header-border-style;

      &__header {
        margin: 0 20px;
      }
    }

    &-item {
      display: flex;
      width: 60px;
      height: $header-height;
      border-right: $header-border-style;
      color: $color-primary-black;
      cursor: pointer;

      &.selected {
        .header__list-item-link {
          border-bottom: 4px solid $color-primary-red;
          background: $color-primary-black;
          color: $color-primary-white;

          svg {
            background: $color-primary-black;
            fill: $color-primary-white;
          }
        }
      }

      &.active {
        .header__dropdown-button {
          border-bottom: 4px solid $color-primary-red;
          background: $color-primary-white;
          color: $color-primary-black;
        }
      }

      &.last {
        border-right: 0;
      }
    }

    &--jobs,
    &--timezone {
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-right: $header-border-style;
      color: $color-primary-black;

      span:first-of-type {
        align-self: center;
        padding: 2px 5px 0 0;
        color: $color-secondary-blue;
        font-family: $font-family-bold;
      }

      svg {
        height: 14px;
        margin: 0 10px 0 0;
        fill: $color-secondary-blue;
      }
    }

    &--timezone {

      svg {
        margin-right: 5px;
        fill: $color-gray-extended-4;
      }
    }
  }

  &__dropdown {
    display: flex;
    position: relative;
    border-right: $header-border-style;
    font-size: 14px;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: $header-height;
      padding: 0;
      border: 0;
      border-bottom: 4px solid transparent;
      background: $color-primary-white;
      cursor: pointer;

      &-assuming-role {
        border: 12px solid #CCC;
      }

      &:after {
        display: inline-block;
        width: 0;
        height: 0;
        content: '';
        cursor: pointer;
      }

      &:hover,
      &:focus {
        border-bottom: 4px solid transparent;
        color: $color-primary-black;

        svg {
          fill: $color-gray-extended-4;
        }
      }

      div,
      form {
        align-self: center;
      }
    }

    .link {
      padding: 10px 0;
      line-height: normal;
      white-space: nowrap;

      a {
        border-bottom: 1px solid $color-secondary-blue;
        color: $color-secondary-blue;
        font-family: $font-family-medium;
      }
    }

    &-list {
      position: absolute;
      top: calc(100% + 1px);
      right: 0;
      min-width: calc(100% + 2px);
      margin: 0;
      border-bottom: 0;
      background: $color-primary-white;
      color: $color-primary-black;
      z-index: map-get($z-index, profile);

      .dropdown {
        min-width: 200px;
        padding: 0;

        &__content-user {
          font-size: 14px;

          >div {
            padding: 15px 30px;
            border-top: 1px solid $color-gray-extended-6;

            &:first-of-type {
              padding: 20px 30px;
              border-top: 0;
            }
          }

          button {
            padding: 0;
            border: 0;
            background: $color-primary-black;
            color: $color-primary-white;
            font-family: $font-family-medium;
          }

          &-detail {
            display: flex;
            flex-direction: column;
            cursor: auto;

            .user-name {
              font-family: $font-family-bold;
            }

            .user-customer,
            .user-site {
              margin-top: 5px;
              font-size: 10px;
            }

            .user-customer {
              color: $color-gray-extended-3;
            }

            .user-site {
              color: $screen-background-1;
            }
          }

          &-version {
            button {
              display: flex;
              flex-direction: column;
            }

            &--version {
              font-size: 10px;
            }
          }

          &-link {
            &:hover {

              /* stylelint-disable max-nesting-depth */
              button {
                color: $color-gray-extended-2;
              }
            }
          }
        }
      }
    }

    &.hide {
      .header__dropdown-list {
        display: none;
      }
    }

    &.open,
    &.selected {
      .header__dropdown-button {
        border-bottom: 4px solid $color-primary-red;
        background: $color-primary-black;
      }

      svg {
        fill: $color-primary-white;
      }
    }

    &.open {
      .header__dropdown-list {
        display: block;
        box-shadow: -10px 10px 20px #0003;
      }
    }
  }

  .swversion-modal {
    display: flex;
    flex-direction: column;

    &__title {
      margin-bottom: 5px;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }

    &__value {
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 14px;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  .session-expiration-modal {
    p {
      margin-bottom: 10px;

      &:last-child {
        margin-top: 20px;
      }
    }

    &__countdown {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .assumerole .selectbox.light {
    margin-bottom: 20px;
  }

}
