.alarms {
  .dropdown-date-period-selector {
    width: 280px;
  }

  .table__data-cell a {
    color: $color-secondary-blue;
    text-decoration: underline;
  }

  .loading--fullscreen {
    z-index: map-get($z-index, modal-loader);
  }

  .toolbar-tabs__container-tabset-btn-content {
    svg {
      width: 24px;
      height: 24px;
      margin-top: 9px;
    }
  }
}

.alarms-subheading {
  display: flex;
  flex-direction: row;

  &__counts {
    font-family: $font-family-roman;

    span {
      margin-right: 6px;
    }
  }

  &__count {
    font-family: $font-family-bold;
  }
}

.alarms-container {
  position: relative;
  height: calc(100vh - 220px);
  margin: 0 30px;
}

.alarm-icon {
  position: relative;
  top: 3px;
  height: 14px;
  margin-right: 10px;

  &__Minor {
    fill: $color-secondary-blue;
  }

  &__Major {
    fill: $color-secondary-yellow;
  }

  &__Critical {
    fill: $color-secondary-orange;
  }
}

.alarm-actions {
  display: flex;
  flex-direction: row;

  svg {
    height: 14px;
    margin-right: 10px;
    margin-left: 10px;
  }

  button {
    border-bottom: none;
  }
}

.alarms-more {
  align-items: center;
  width: 36px;
  border-left: 1px solid $color-map-bg-hybrid;

  svg {
    fill: $color-primary-white;
    margin: 30px 14px;
  }
}

.alarm-clear {

  b {
    font-family: $font-family-bold;
  }
}

.alarms-subheading-container {
  display: flex;
  justify-content: space-between;
  margin: 15px 30px;
}

.alarms-clearbytype-link {
  display: flex;

  svg {
    height: 14px;
    margin-right: 8px;
  }

  button {
    font-family: $font-family-medium;
    font-size: 14px;
  }
}

.alarms-clearbytype-dropdown {
  position: absolute;
  top: 177px;
  right: 30px;
  width: 205px;
  border: 1px solid $color-gray-extended-2;
  background: #FFF 0% 0% no-repeat padding-box;
  box-shadow: 0 0 10px $color-gray-extended-7;
  z-index: 1000;
}

.actions-alarms {
  position: relative;

  .dropdown {
    position: absolute;
    top: 80px;
    right: 0px;
    width: 188px;
    padding: 0;
    z-index: 5;

    .actions-alarms__element {
      display: flex;
      width: 100%;
      padding: 20px;
      border: 0;
      border-bottom: 1px solid $color-gray-extended-5;
      outline: 0;
      background: transparent;
      color: $color-primary-white;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;

      &:after {
        display: none;
      }

      &:last-child {
        border-bottom: 0;
      }

      svg {
        height: 18px;
        margin-right: 10px;
        fill: $color-primary-white;
      }

      &:hover {
        color: $color-gray-extended-2;
      }
    }
  }
}
