.admin {
  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 220px;
    background-color: $color-primary-black;
    color: $color-primary-white;

    .sub-user-items {
      .admin__sidebar-item-title {
        margin-left: 45px;
      }
    }
  }

  &__sidebar-title-section {
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 20px;
    border-bottom: 1px solid $color-gray-extended-7;
  }

  &__sidebar-title {
    font-family: $font-family-bold;
    font-size: 24px;
  }

  &__sidebar-site-name {
    margin-top: 5px;
    color: $color-secondary-yellow;
    font-family: $font-family-medium;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__sidebar-item {
    display: flex;
    flex-direction: column;
    height: 50px;
    border: 0;

    svg {
      width: 16px;
      height: 18px;
      fill: $color-gray-extended-4;
    }

    &-icon {
      display: flex;
      align-items: center;
      height: 100%;
      border-bottom: 1px solid $color-gray-extended-7;
    }

    &-expandicon {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: auto;

      svg {
        width: 8px;
        stroke: $color-gray-extended-3;
        fill: $color-gray-extended-3;
      }
    }

    &-title {
      margin-left: 30px;
      color: $color-primary-white;
      font-family: $font-family-roman;
      font-size: 14px;
    }

    &-line {
      position: relative;
      bottom: -1px;
      width: 8%;
      margin-left: 10px;
      border-bottom: 1px solid transparent;
    }

    &--selected {
      background-color: $color-gray-extended-7;

      .admin__sidebar-link {
        border-right: 5px solid $color-primary-red;
        background-color: $color-gray-extended-7;
      }

      .admin__sidebar-item-line {
        border-bottom: 1px solid $color-gray-extended-7;
      }

      .admin__sidebar-item-title {
        color: $color-primary-white;
      }

      .admin__sidebar-item-icon {
        svg , svg > path {
          fill: $color-primary-red;
        }
      }
    }

    &--subselected {
      background-color: $color-primary-black;

      .admin__sidebar-link {
        border-right: 0;
        background-color: $color-gray-extended-7;
      }

      .admin__sidebar-item-line {
        border-bottom: 1px solid $color-gray-extended-7;
      }

      .admin__sidebar-item-title {
        color: $color-primary-white;
      }

      .admin__sidebar-item-icon {
        svg {
          fill: $color-primary-red;
        }
      }

      .admin__sidebar-item-expandicon {
        svg {
          width: 14px;
          stroke: $color-primary-white;
          fill: $color-primary-white;
        }
      }
    }

    &--subitem {
      background-color: $color-primary-black;

      .admin__sidebar-item-title {
        font-size: 12px;
      }
    }

    &--greybg {
      .admin__sidebar-link {
        background-color: $color-gray-extended-7;
      }
    }
  }

  &__sidebar-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    border: 0;
    background-color: $color-primary-black;
    color: $color-primary-white;
  }
}
