.search {
  display: flex;
  position: relative;
  border: $header-border-style;
  font-size: 14px;
  line-height: 40px;

  &__input {
    width: 100%;
    border: 0;
    border-bottom: 2px solid $color-gray-extended-2;
    background: transparent;
    color: $color-gray-extended-2;
    line-height: 40px;
  }

  &__icon {
    position: absolute;
    top: 11px;
    right: 10px;
    fill: $color-primary-black;
  }

  &.dark,
  &.light {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 0;
    border: 0;

    .search__input {
      width: 100%;
      padding: 0 25px 0 0;
      border: 0;
      border-bottom: 2px solid $color-gray-extended-2;
      background: transparent;
      color: $color-gray-extended-2;
      line-height: 40px;
    }

    .search__icon {
      fill: $color-primary-white;
    }
  }

  &.light {
    .search__input {
      color: $color-primary-black;
    }

    .search__icon {
      fill: $color-primary-black;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      span {
        width: 100%;
        padding: 0 10px;
        border-left: 4px solid transparent;

        &:hover {
          border-left: 4px solid $color-primary-black;
        }

        &.selected {
          border-left: 4px solid $color-primary-red;
        }
      }
    }
  }

  &.search-site__searchox {
    width: 300px;
    height: 40px;

    .search__icon {
      top: unset;
      right: 10px;
      bottom: 15px;
      width: 12px;
      cursor: pointer;
      stroke: $color-primary-black;
    }

    .search__input {
      position: relative;
      height: 40px;
      padding: 15px;
      border: 1px solid $color-gray-extended-2;
      border-bottom: 1px solid $color-primary-black;
      color: $color-primary-black;
      font-family: $font-family-roman;
      line-height: 40px;

      &::placeholder {
        color: $color-primary-black;
      }
    }

    .ReactVirtualized__Grid {
      position: absolute;
      top: 40px;
      width: 178px;
      border: 1px solid $color-gray-extended-5;
      background: $color-primary-white;
      z-index: 5;

      .search-partner--list {
        display: flex;
        align-items: center;
        border-top: 1px solid $color-gray-extended-5;
        color: $color-primary-black;
        line-height: 40px;
        cursor: pointer;

        span {
          color: $color-primary-black;
          font-size: 14px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
