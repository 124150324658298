.tooltip {
  &__element {
    padding: 10px;
    border: 1px solid $color-gray-extended-2;
    background: $color-primary-white;
    color: $color-primary-black;
    font-family: $font-family-roman;
    font-size: 14px;
    white-space: nowrap;
    box-shadow: 0 0 20px hsla(0, 0%, 0%, .2);
    z-index: map-get($z-index, tooltip);

    .arrow,
    .arrow:before {
      position: absolute;
      width: 11px;
      height: 11px;
      background: inherit;
    }

    .arrow {
      visibility: hidden;
    }

    .arrow:before {
      transform: rotate(45deg);
      content: '';
      visibility: visible;
    }

    &[data-popper-placement^='top'] > .arrow {
      bottom: -6px;

      &:before {
        border-right: 1px solid $color-gray-extended-2;
        border-bottom: 1px solid $color-gray-extended-2;
      }
    }

    &[data-popper-placement^='bottom'] > .arrow {
      top: -6px;

      &:before {
        border-top: 1px solid $color-gray-extended-2;
        border-left: 1px solid $color-gray-extended-2;
      }
    }

    &[data-popper-placement^='left'] > .arrow {
      right: -6px;

      &:before {
        border-top: 1px solid $color-gray-extended-2;
        border-right: 1px solid $color-gray-extended-2;
      }
    }

    &[data-popper-placement^='right'] > .arrow {
      left: -6px;

      &:before {
        border-bottom: 1px solid $color-gray-extended-2;
        border-left: 1px solid $color-gray-extended-2;
      }
    }

    &.energy-tooltip {
      width: 322px;
      height: 52px;
      transform: translate3d(35px, 6px, 0);
      white-space: normal;

      .arrow {
        transform: translate3d(161px, 0, 0);
      }
    }
  }
}

.spec-tooltip {
  position: relative;

  &__element {
    position: absolute;
    top: 0;
    padding: 10px;
    border: 1px solid $color-gray-extended-2;
    background: $color-primary-white;
    color: $color-primary-black;
    font-family: $font-family-roman;
    font-size: 14px;
    white-space: nowrap;
    box-shadow: 0 0 20px hsla(0, 0%, 0%, .2);
    z-index: map-get($z-index, tooltip);

    &:after,
    &:before {
      position: absolute;
      border: solid transparent;
      content: '';
      pointer-events: none;
    }

    &.top {
      margin: 0 50%;
      transform: translate(-50%, -100%);

      &:after,
      &:before {
        top: 100%;
        left: 50%;
      }

      &:after {
        margin-left: -10px;
        border-width: 10px;
        border-color: rgba(216, 218, 218, 0);
        border-top-color: $color-primary-white;
      }

      &:before {
        margin-left: -11.5px;
        border-width: 11.5px;
        border-color: rgba(216, 218, 218, 0);
        border-top-color: $color-gray-extended-2;
      }
    }
  }
}
