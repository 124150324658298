.jobs-list {
  overflow: hidden;
  display: block;
  height: 100%;
  padding-top: 10px;
  background-color: $screen-background-1;
}

.jobs-list-item {
  display: flex;
  height: 70px;
  margin: 0 26px;
  border: 1px solid $screen-background-1;
  background-color: $color-primary-white;
  font-size: 12px;
  line-height: 14px;
  box-shadow: 0 0 5px $color-list-shadow-box;

  &__type {
    display: flex;
    align-items: center;
    width: 311px;
    margin-left: 20px;
    border-right: 1px solid $color-modal-separator;
    font-family: $font-family-medium;
    font-size: 18px;

    svg {
      margin-right: 5px;
    }
  }

  &__info,
  &__node-info,
  &__date,
  &__user,
  &__status,
  &__action,
  &__firmware,
  .job-status-btns {
    display: flex;
    margin-left: 20px;
    border-right: 1px solid $color-modal-separator;
  }

  &__section {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;

    &-title {
      margin-top: auto;
      margin-bottom: auto;
      font-family: $font-family-medium;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 168px;
    height: 100%;

    .jobs-list-item__section-title {
      margin-bottom: 6px;
    }

    .jobs-list-item__section-content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.long {
      width: 349px;
    }
  }

  &__user {
    .jobs-list-item__section-content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__node {
    width: 173px;
    height: 100%;

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      &--addRemove {
        display: flex;

        .jobs-list-item__section-title {
          width: 82px;
        }
      }

      .jobs-list-item__section-title,
      span {
        margin-top: auto;
        margin-right: 5px;
        margin-bottom: auto;
      }
    }
  }

  &__date {
    flex-direction: column;
    width: 105px;
    padding-right: 20px;

    .jobs-list-item__section-title {
      margin-bottom: 4px;
    }

    &.long {
      width: 346px;
    }
  }

  &__user {
    flex-direction: column;
    width: 105px;
    padding-right: 20px;

    .jobs-list-item__section-title {
      margin-bottom: 4px;
    }
  }

  &__status {
    width: 176px;
    border: 0;

    svg,
    span {
      margin-top: auto;
      margin-right: 10px;
      margin-bottom: auto;
    }

    svg {
      min-width: 18px;
    }

    &.job-in-progress {
      font-style: italic;
    }

    &.job-succeeded {
      color: $color-secondary-green;
    }

    &.job-failed {
      color: $color-secondary-orange;
    }

    &.job-partially-succeeded {
      color: $color-secondary-orange;
    }
  }

  .job-status-btns {
    margin-left: auto;
  }

  &__details {
    display: flex;
    width: 91px;
    border-left: 1px solid $color-modal-separator;

    .btn {
      margin: auto;
    }
  }

  &__action {
    justify-content: center;
    width: 60px;
    border: 0;
    border-left: 1px solid $color-modal-separator;
    font-size: 12px;

    svg {
      position: relative;
      top: 24px;
    }
  }

  &__firmware {
    flex-direction: column;
    width: 350px;
    padding-right: 20px;
    font-size: 12px;
    line-height: 15px;

    &-container {
      margin-top: auto;
      margin-bottom: auto;

      .jobs-description {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

    }

    span {
      display: inline-block;
      width: 78px;
      font-family: $font-family-bold;
    }
  }
}
