.notification-bars {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: map-get($z-index, notifications);
}

.notification-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 25px;
  color: $color-primary-white;
  font-size: 14px;

  svg {
    height: 14px;
    fill: $color-primary-white;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__message {
    margin-left: 20px;
  }

  &--success {
    background-color: $color-secondary-green;
  }

  &--error {
    background-color: $color-secondary-orange;
  }

  &--warning {
    background-color: $color-secondary-yellow;
    color: $color-primary-black;

    svg {
      fill: $color-primary-black;
    }
  }

  &--info {
    background-color: $color-secondary-blue;
  }

  &__close-btn {
    position: absolute;
    right: 5px;
    border: 0;
    outline: none;
    background-color: transparent;
  }
}
