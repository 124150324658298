.sitemanager {
  .sitemanager-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: auto;

    > .tooltip {
      width: 14px;
      min-width: 14px;
      height: 14px;
      margin-right: 15px;
      cursor: pointer;

      svg {
        position: absolute;
        height: 14px;
      }
    }
  }
}
