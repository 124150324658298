.delete-group {
  font-size: 14px;
  line-height: 18px;

  &__confirm {
    display: flex;

    span {
      font-family: $font-family-medium;
    }

    svg {
      margin-right: 7px;
      fill: $color-secondary-yellow;
    }
  }

  &__warning {
    margin-top: 15px;
    line-height: 16px;
  }
}
