.schedule-list {
  &__element {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 2px;
    border: 1px solid $color-gray-extended-5;
    background-color: $color-gray-extended-6;
    color: $color-primary-white;

    svg {
      fill: $color-primary-white;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 100%;
    padding: 0 10px;
    border: 0;
    border-right: 1px solid $color-gray-extended-5;
    outline: 0;
    background-color: transparent;

    &--active {
      background-color: $color-gray-extended-8;

      &:before {
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        width: 0;
        height: 0;
        border-bottom: 9px solid transparent;
        border-left: 9px solid $color-secondary-yellow;
        content: '';
      }

      svg {
        fill: $color-secondary-yellow;
      }
    }
  }

  &__name-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 10px;
    border-right: 1px solid $color-gray-extended-5;
    font-family: $font-family-medium;
    font-size: 12px;

    &--schedule {
      width: 100%;
      min-width: 0;
    }

    &--groups {
      width: 185px;
    }
  }

  &__name {
    flex-direction: column;
    justify-content: center;
    width: 164px;
    min-width: 164px;
    max-width: 164px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__description {
    color: $color-gray-extended-3;
    font-family: $font-family-roman;
    font-size: 10px;
    line-height: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__special-date {
    font-family: $font-family-roman;

    svg {
      width: 14px;
      height: 14px;
      fill: $color-secondary-yellow;
    }
  }

  &__groups {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    padding: 0 10px;
    border: 0;
    background-color: transparent;

    &:hover {
      background-color: $color-gray-extended-8;
    }

    &-value {
      color: $color-secondary-yellow;
      font-family: $font-family-bold;
    }

    &-text {
      color: $color-primary-white;
      font-size: 10px;
    }

    &-btn {
      display: flex;
      align-items: center;
      width: 160px;
      height: 100%;
      padding-left: 15px;

      .btn {
        font-family: $font-family-medium;
      }

      .btn-textlink {
        border-bottom: 1px solid $color-secondary-blue-80;
        color: $color-secondary-blue-80;
      }
    }

    &-add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 100%;
      padding: 0 10px;
      border-left: 1px solid $color-gray-extended-5;

      &-btn {
        border: 0;
        outline: 0;
        background-color: transparent;
      }
    }
  }

  &__edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    padding: 0 10px;
    border-left: 1px solid $color-gray-extended-5;
    cursor: pointer;
  }
}
