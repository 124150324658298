.assigned-groups {
  color: $color-primary-black;

  &__schedule {
    display: flex;
    padding: 15px;
    border: 1px solid $color-secondary-blue-40;
    background-color: $color-secondary-blue-5;

    svg {
      width: 33px;
      height: 30px;
      fill: $color-secondary-blue-40;
    }

    &-text {
      color: $color-primary-black;
      font-family: $font-family-medium;
      font-size: 14px;
    }

    &-name {
      color: $color-secondary-blue;
      font-family: $font-family-medium;
      font-size: 16px;
    }
  }

  &__section {
    margin-top: 20px;
    font-size: 12px;

    &-value {
      margin-right: 2px;
      font-family: $font-family-bold;
    }
  }

  &__list-title {
    margin: 20px 0 5px;
    font-family: $font-family-medium;
    font-size: 18px;
  }

  &__list {
    margin-top: 5px;
    border: 1px solid $color-primary-black;
  }

  &__list-empty {
    border: 0;
  }

  &__name {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    border: 0;
    border-top: 1px solid $color-gray-extended-4;
    border-left: 4px solid transparent;
    outline: 0;
    background-color: transparent;
    font-size: 14px;

    &--selected {
      border-left: 4px solid $color-primary-red;
    }

    &--current {
      &:after {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 5px;
        height: 100%;
        margin-left: auto;
        color: $color-gray-extended-4;
        content: 'Current';
      }
    }
  }

  &__info {
    display: flex;
    margin: 30px 0 20px;
    font-size: 12px;

    svg {
      margin-right: 5px;
      fill: $color-secondary-blue;
    }
  }

  &__warning {
    display: flex;
    font-size: 12px;

    &--medium {
      margin: 0 2px;
      font-family: $font-family-medium;
    }

    svg {
      margin-right: 5px;
      fill: $color-secondary-orange;
    }
  }
}
