.jobdetails-modal {
  h2 {
    position: relative;
    left: 28px;
    width: 350px;
  }

  .copyjobid {
    position: absolute;
    top: 34px;
    left: 28px;
  }

  .refreshJobDetails {
    position: absolute;
    top: 34px;
    left: 410px;
    cursor: pointer;

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% {  transform: rotate(359deg); }
    }

    &.refreshLoading {
      animation: spin 1s ease-in-out infinite;
      cursor: default;
      fill: $color-gray-extended-4;
      pointer-events: none;
    }
  }
}

.jobdetails-header {
  display: flex;
  position: absolute;
  top: 0;
  right: 40px;
  flex-direction: row;
  font-size: 12px;

  &__startdate,
  &__enddate,
  &__status,
  &__username,
  &__firmware {
    width: 180px;
    height: 33px;
    margin: 26px 0;
    padding: 0 12px;
    border-left: 1px solid $color-modal-separator;
    font-family: $font-family-roman;

    &>div:first-child {
      margin-bottom: 6px;
      font-family: $font-family-medium;
      font-size: 12px;
    }
  }

  &__username {
    width: 140px;
    margin-left: 80px;
  }

  &__status span {
    position: relative;
    left: 0;
    margin-left: 0;
    font-size: 12px;
  }

  &__status svg {
    position: relative;
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  &__firmware > div {
    height: 13px;

    div:first-child {
      display: inline-block;
      font-family: $font-family-bold;
    }

    div:last-child {
      float: right;
      font-family: $font-family-medium;
      text-align: right;
    }
  }
}

.jobdetails-summary {
  display: flex;
  position: absolute;
  top: 79px;
  left: 30px;
  flex-direction: column;
  width: 110px;

  &__total,
  &__processed,
  &__succeeded,
  &__failed,
  &__skipped,
  &__stopped {
    height: 81px;
    margin-bottom: 2px;
    padding: 20px;
    border-right: 5px solid $color-gray-extended-6;
    background-color: $color-gray-extended-6;
    color: $color-primary-white;
    font-family: $font-family-bold;
    font-size: 18px;

    &:hover {
      border-right: 4px solid $color-primary-red;
    }

    &>div:first-child {
      margin-top: 4px;
      margin-bottom: 4px;
      color: $color-gray-extended-2;
      font-family: $font-family-medium;
      font-size: 12px;
    }
  }

  &__failed>span {
    color: $color-secondary-orange;
  }

  &__succeeded>span {
    color: $color-secondary-green;
  }
}

.jobdetails-container{
  width: 100%;
  padding-left: 140px;
  font-size: 16px;

  .table__data-cell {
    display: inline-block;
  }

  &__failed {
    color: $color-secondary-orange;
  }

  &__succeeded {
    color: $color-secondary-green;
  }

  &__stopped,
  &__skipped {
    color: $color-gray-extended-4;
  }
}

.jobdetails-modal > .modal__content {
  height: 500px;
}
