.jobs-ownjobsonly {
  position: absolute;
  top: 90px;
  right: 40px;
}

.jobs-ownjobsonly > div {
  display: inline-block;
  padding-left: 16px;
}
