.dimmingpoints-date {
  width: 262px;
  height: 172px;
  margin-top: 10px;
  border: 1px solid $color-gray-extended-2;

  &__title {
    display: flex;
    justify-content: space-around;
    height: 34px;

    &-text {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      padding-bottom: 2px;
      border: 0;
      border-bottom: 1px solid $color-gray-extended-2;
      outline: 0;
      background: $screen-background-1;
      font-family: $font-family-medium;
      font-size: 12px;
      line-height: 14px;

      span {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &.selected {
        padding-bottom: 0;
        border-bottom: 3px solid $color-primary-red;
        background: $color-primary-white;
      }
    }
  }

  &__content {
    padding: 15px 20px 0;

    &-title {
      margin-bottom: 15px;
      font-family: $font-family-medium;
      font-size: 16px;
      line-height: 18px;
    }

    &-list {
      display: flex;
      justify-content: center;
      margin: auto;

      .day {
        display: flex;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin: auto;
        margin-right: 2px;
        border: 1px solid $color-primary-black;
        background: $color-primary-white;

        &:last-child {
          margin-right: 0;
        }

        span {
          height: fit-content;
          margin: auto;
          font-family: $font-family-medium;
          font-size: 14px;
          line-height: 17px;
          text-transform: capitalize;
        }

        &.selected {
          background: $color-primary-black;
          color: $color-primary-white;
        }

        &[disabled] {
          border-color: $color-gray-extended-2;
          color: $color-gray-extended-2;
          cursor: not-allowed;
        }
      }
    }

    &-footer {
      display: flex;
      margin-top: 15px;
      margin-bottom: 15px;

      svg {
        width: 22px;
        font-size: 22px;
        fill: $color-secondary-blue;
      }

      > span {
        margin-left: 6px;
        font-family: $font-family-roman;
        font-size: 12px;
        line-height: 14px;

        .bold {
          font-family: $font-family-medium;
        }
      }
    }

    &.spec-date {
      .dimmingpoints-date__content-title {
        margin-bottom: 5px;
      }

      .react-datepicker__tab-loop {
        position: absolute;
      }
    }
  }

  &.readonly {
    height: auto;
    margin-top: 55px;
    border: 0;

    .dimmingpoints-date__content-list {
      .day {
        cursor: default;
      }
    }

    .dimmingpoints-date__content-date {
      font-size: 14px;
    }
  }
}
