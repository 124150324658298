/* stylelint-disable declaration-no-important */
.react-datepicker-container {
  margin-bottom: 10px;

  &--side {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .react-datepicker {
      &__popper {
        &--popup {
          top: 35px !important;
        }

        &--inline {
          position: relative !important;
          margin-top: -5px;
        }
      }
    }
  }

  &--above {
    .react-datepicker {
      &__popper--popup {
        top: 30px !important;
      }

      &__popper--inline {
        position: relative !important;
        margin-top: -5px;
      }
    }
  }

  .react-datepicker {
    padding: 3px;
    border: 1px solid $color-gray-extended-5;
    border-radius: 0;
    background-color: $color-gray-extended-7;

    &-inner-container {
      position: relative;
    }

    &-popper {
      transform: translate3d(0, 0, 0) !important;
      z-index: map-get($z-index, datepicker);
    }

    &--dark {
      border-top: 1px solid $color-gray-extended-5;
      border-right: 1px solid $color-gray-extended-5;
      border-bottom: 1px solid $color-gray-extended-4;
      border-left: 1px solid $color-gray-extended-5;
      border-radius: 0;
      background-color: $color-gray-extended-7;
      color: $color-gray-extended-2;

      .react-datepicker {
        &__header {
          background-color: $color-gray-extended-7;
          color: $color-gray-extended-2;
        }

        &__day,
        &__day-name,
        &__current-month {
          color: $color-gray-extended-2;
        }

        &__day {
          &--outside-month,
          &--disabled {
            color: $color-gray-extended-5;
          }

          &--selected {
            background-color: $color-secondary-yellow-calendar;
            color: $color-gray-extended-7;
          }

          &:hover {
            color: $color-gray-extended-7;
          }
        }
      }
    }

    &--light {
      border-top: 1px solid $color-gray-extended-2;
      border-right: 1px solid $color-gray-extended-2;
      border-bottom: 1px solid $color-gray-extended-7;
      border-left: 1px solid $color-gray-extended-2;
      border-radius: 0;
      background-color: $color-primary-white;
      color: $color-primary-black;

      &.react-datepicker {
        width: calc(100% + 1px);
        border-top: 1px solid $color-gray-extended-7;
        border-right: 1px solid $color-gray-extended-7;
        border-bottom: 1px solid $color-gray-extended-7;
        border-left: 1px solid $color-gray-extended-7;
      }

      .react-datepicker {
        &__header {
          background-color: $color-primary-white;
          color: $color-primary-black;
        }

        &__day,
        &__day-name,
        &__current-month {
          color: $color-primary-black;
        }

        &__day {
          &--outside-month,
          &--disabled {
            color: $color-gray-extended-5;
          }

          &--selected {
            background-color: $color-secondary-yellow-calendar;
            color: $color-primary-black;
          }
        }
      }
    }

    &__label {
      &--above {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: normal;
      }

      &--side {
        margin-right: 10px;
        font-family: $font-family-bold;
        font-size: 14px;
        line-height: normal;
      }

      &--required {
        margin: 0;
        color: $color-primary-red;
        font-size: 14px;
        line-height: 16px;
      }
    }

    &-wrapper {
      display: block;
      margin: 5px 0;

      input {
        width: 220px;
        height: 40px;
        padding: 0 15px;
        background-repeat: no-repeat;
        background-position: right;
        font-family: $font-family-roman;
        font-size: 14px;
        line-height: 16px;
        background-origin: content-box;
      }
    }

    &__header {
      border-bottom: 0;
    }

    &__day {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 23px;
      height: 23px;
      font-family: $font-family-roman;
      font-size: 12px;

      &--selected {
        border-radius: 0;
      }

      &--disabled {
        cursor: not-allowed;
        pointer-events: none;
      }

      &--today {
        font-weight: normal;
      }

      &:hover {
        border-radius: 0;
      }
    }

    &__day-name,
    &__current-month {
      font-family: $font-family-medium;
    }

    &__current-month {
      font-size: 16px;
    }

    &__day-name {
      width: 23px;
    }
  }

  &--dark {
    .react-datepicker {
      &-wrapper {
        input {
          background-image: url('../../img/icons/calendar-white.svg');
        }
      }
    }
  }

  &--light {
    .react-datepicker {
      &-wrapper {
        input {
          background-image: url('../../img/icons/calendar.svg');
        }
      }
    }
  }
}

.react-datepicker__navigation--previous {
  width: 15px;
  height: 15px;
  border: none;
  background: url('../../img/icons/left-arrow-gray.svg') no-repeat;
}

.react-datepicker__navigation--next {
  width: 15px;
  height: 15px;
  border: none;
  background: url('../../img/icons/right-arrow-gray.svg') no-repeat;
}
