.checkbox {
  display: flex;
  align-content: center;

  &__button {
    width: 20px;
    height: 20px;
    padding: 2px 4px;
    border: 1px solid $color-gray-extended-3;
    background-color: $color-gray-extended-7;

    svg {
      fill: $color-gray-extended-3;
    }
  }

  &--light {
    display: flex;
    align-content: center;

    &__button {
      width: 20px;
      height: 20px;
      padding: 2px 4px;
      border: 1px solid $color-primary-black;
      background-color: $color-primary-white;

      svg {
        fill: $color-primary-black;
      }
    }
  }

  &--disabled {
    display: flex;
    align-content: center;
    color: $color-gray-extended-5;

    .checkbox--light__button {
      border: 1px solid $color-gray-extended-2;

      svg {
        fill: $color-gray-extended-3;
      }
    }
  }

  &--small {
    button {
      width: 14px;
      height: 14px;
      padding: 0;

      svg {
        width: 8px;
        margin-bottom: 2px;
      }
    }
  }
}
