.partner-form {
  &__inputs {
    display: flex;

    &-contact {
      width: 50%;
      margin-top: 30px;
      padding-right: 40px;
      border-right: 1px solid $screen-background-1;
    }

    &-address {
      width: 50%;
      margin-top: 30px;
      margin-left: 40px;
    }
  }

  &__subtitles {
    margin-bottom: 15px;
    font-family: $font-family-medium;
    font-size: 18px;
  }

  &__state {
    display: grid;
    grid-template-columns: 1fr 35%;
    grid-column-gap: 15px;

    .textbox {
      margin-bottom: 0;
    }
  }
}
