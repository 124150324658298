.fota-campaigns {
  .modal.create-edit-campaign {
    .modal__content {
      flex-direction: column;

      .textbox {
        margin-top: 0 !important;
      }

      .selectbox.light {
        margin-top: 0;
        margin-bottom: 15px;
      }

      .radio-button-label {
        margin: 0 0 5px;
        font-family: $font-family-medium;
        font-size: 14px;
      }

      .radio-buttons__element:last-of-type {
        margin-bottom: 15px;
      }
    }
  }

  .create-edit-campaign-success {
    display: flex;
    flex-direction: row;

    svg {
      width: 18px !important;
      height: 18px !important;
    }

    &--item {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 0 !important;

      .title {
        font-family: $font-family-medium;
      }

      .description {
        margin-bottom: 20px;
        font-style: italic;
        white-space: normal;
      }
    }
  }

  .fota-campaign-actions {
    display: flex;
    align-items: center;
    height: 40px;

    &--item {
      display: flex;
      min-width: 25px;
    }

    svg {
      cursor: pointer;
    }

    .btn {
      margin-left: 5px;
    }
  }
  .status-dot {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;

    &.active {
      background-color: $color-secondary-green;
    }

    &.created {
      background-color: $color-secondary-blue;
    }

    &.closed {
      background-color: $color-secondary-yellow-map;
    }

    &.deleted {
      background-color: $color-secondary-orange;
    }

    &.failed {
      background-color: $color-secondary-pink;
    }
  }
}

.add-node-to-fota-campaign {
  font-size: 14px;
  line-height: 18px;

  &__confirm {
    display: flex;

    span {
      font-family: $font-family-medium;
    }

    svg {
      width: 30px;
      height: 18px;
      margin-right: 7px;
      fill: $color-secondary-yellow;
    }
  }

  &__warning {
    margin-top: 15px;
    line-height: 16px;
    white-space: normal;
  }
}
