.dropdown-jobs-sort {
  position: absolute;
  top: 80px;
  width: 229px;
  padding: 0;
  z-index: map-get($z-index, dropdown);
}

.jobs-sort {
  .selectbox {
    margin-bottom: 0;
  }

  .selectbox__label,
  .selectbox__header {
    display: none;
  }
}
