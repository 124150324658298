.loading {
  align-items: center;
  justify-content: center;
  background-color: $backdrop-loader;
  z-index: map-get($z-index, loader);

  &--fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &--non-fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 4px solid $color-primary-white;
    animation: loader 2s infinite ease;

    &-inner {
      display: inline-block;
      width: 100%;
      background-color: $color-primary-white;
      vertical-align: top;
      animation: loader-inner 2s infinite ease-in;
    }
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
