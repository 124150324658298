.dimmingpoints {
  display: flex;
  margin: 20px 20px auto 55px;

  &__timeline {

    &-title {
      margin-bottom: 15px;
      margin-left: -30px;
      font-family: $font-family-bold;
      font-size: 24px;
      line-height: 24px;
    }
  }
}
