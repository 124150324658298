// Project API Keys:

.projects {
  margin: 0;

  .tabletoolbar.accounts .tabletoolbar__children .textbox input, .tabletoolbar.users .tabletoolbar__children .textbox input {
    width: 230px;
    &::placeholder {
      color: $color-gray-extended-4;
    }
  }
}
.project {
  height: auto;
  margin: 10px 0 25px;
  padding: 20px;
  padding-bottom: 30px;
  border: 1px solid #EFEFEF;
  background-color: #FFF;
  box-shadow: 0 0 5px #00000026;
  overflow: hidden;

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &-name {
      display: flex;
      flex-direction: column;
      font-family: $font-family-bold;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;

      &-title {
        display: flex;
        align-items: center;
        font-size: 12px;
      }

      svg {
        width: 24px;
        margin-right: 10px;
      }
    }
  }
}

.project__actions-text {
  margin: 5px 0 0 0;
  color: $color-gray-extended-4;
  font-family: $font-family-roman;
  font-size: 14px;
}

.project__actions-partneraccount-value {
  font-family: $font-family-roman;
  font-size: 12px;
  margin-right: 5px;
  color: $color-primary-black;
}

.no_border {
  border: 0 none !important;
}

.trashButton {
  border: 0;
  justify-content: right;
  display: flex;
  margin-right: 10px;
  cursor: pointer;
}
.project-list {
  display: block;
  width: 100%;
  height: 100%;

  button {
    outline: 0;
  }

  &__items {
    display: flex;
    height: 50px;
    padding-left: 15px;
    border: 1px solid $color-gray-extended-2;
    font-size: 14px;
    box-shadow: 0 2px 5px $color-list-shadow-box;

    &-main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      height: 100%;
      border-right: 1px solid $color-gray-extended-2;
    }

    &-name {
      font-family: $font-family-medium;
      line-height: 16px;
    }

    &-id {
      color: $color-gray-extended-4;
      font-size: 10px;
      line-height: 12px;
    }

    &-label {
      font-family: $font-family-medium;
      font-size: 12px;
      line-height: 14px;
    }

    &-city {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 15%;
      height: 100%;
      padding-left: 20px;
      border-right: 1px solid $color-gray-extended-2;
      font-size: 14px;
      line-height: 16px;
    }

    &-state {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 15%;
      height: 100%;
      padding-left: 20px;
      border-right: 1px solid $color-gray-extended-2;
    }

    &-sites {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;
      font-family: $font-family-bold;
      font-size: 18px;

      a {
        color: $color-secondary-blue-80;
      }

      svg {
        height: 18px;
        margin-right: 5px;
      }

      .tooltip {
        font-family: $font-family-roman;
      }
    }

    &-users {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;
      font-family: $font-family-bold;
      font-size: 18px;

      a {
        color: $color-secondary-blue-80;
      }

      svg {
        width: 18px;
        margin-right: 5px;
      }

      .tooltip {
        font-family: $font-family-roman;
      }
    }

    &-edit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;
      border: 0;

      svg {
        height: 18px;
      }
    }

    &-sites,
    &-users {

      &:after {
        position: absolute;
        right: 0;
        width: 1px;
        height: 18px;
        background: $color-gray-extended-2;
        content: '';
      }
    }

    > div:last-child {

      &:after {
        display: none;
      }
    }
  }
}

.copyKeyWarning {
  background-color: #FFF9DE;
  padding: 10px;
  margin-bottom: 10px;
}

.copyKeyWrapper {
  background-color: #E3F2FD;
  padding: 10px;
}

.admin-editproject-modal-content {
  width: 100%;
}
