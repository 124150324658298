.dropdown-alarms-duration {
  position: absolute;
  top: 80px;
  width: 302px;
  padding: 20px 30px;
  z-index: map-get($z-index, dropdown);

  .dropdown__primary-button,
  .dropdown__secondary-button {
    display: flex;
    align-items: flex-end;
    height: auto;
    padding: 0;
    border: 0;
    background: 0;
    color: $color-secondary-blue-80;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
  }

  .dropdown__footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
  }
}

.alarms-duration {
  &__displayby-section {
    display: inline-block;
    width: 120px;
    vertical-align: top;
  }

  &__form-section {
    display: inline-block;
  }

  &__button-bar {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 10px;
    text-align: right;
  }

  &__duration-line {
    display: flex;
    flex-direction: row;

    > div {
      margin-right: 10px;
    }

    .textbox input{
      width: 57px;
    }
  }

  .selectbox {
    margin-bottom: 16px;

    &.dark {
      .selectbox__header {
        line-height: normal;
      }
    }
  }

  .btn-primary {
    margin: 0;
  }

  .btn-secondary {
    margin-right: 0;
    margin-left: 10px;
  }

}
