//z-index
/* stylelint-disable indentation */
$z-index: (
  modal-loader: 2000,
  custom-list: 1030,
  notifications: 1020,
  modal: 1010,
  modal-backdrop: 1005,
  datepicker: 1003,
  selectbox: 1000,
  dropdown: 1000,
  profile: 1002,
  tooltip: 2000,
  loader: 900,
  table-footer: 450,
  map-search: 500,
  resizer: 200,
  table: 1,
  map: 0,
  scedule-modal: 500,
);

// for creating classes, like "margin-top-15", "padding-left-30", etc..
// "margin/padding" - "top/right/bottom/left" - "5/10/15/.../70/75/80"
@each $i in margin, padding {
  @each $j in top, right, bottom, left {
    @for $k from 1 through 20 {
      $l: $k * 5;
      .#{$i}-#{$j}-#{$l} { #{$i}-#{$j}: #{$l}px; }
    }
  }
}

.hide-scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
