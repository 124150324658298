.autocomplete {
  display: flex;
  position: relative;
  font-size: 14px;
  line-height: 40px;

  .textbox {
    width: 100%;
  }

  &__list {
    position: absolute;
    top: 61px;
    z-index: map-get($z-index, dropdown);

    &-element {
      display: flex;
      align-items: center;
      border: 1px solid $color-gray-extended-5;
      border-top: 0;
      background: $color-primary-white;
      color: $color-primary-black;
      line-height: 40px;
      cursor: pointer;

      span {
        color: $color-primary-black;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    span {
      width: 100%;
      padding: 0 10px;
      border-left: 4px solid transparent;

      &:hover {
        border-left: 4px solid $color-primary-black;
      }
    }
  }
}
