.edit-modal {
  display: flex;
  align-items: center;
  height: 27px;
  margin-bottom: 25px;
  font-size: 10px;

  > div {
    height: 100%;
    padding-right: 15px;
    border-right: 1px solid $color-modal-separator;
  }

  &__title {
    padding-right: 15px;
    border-right: 1px solid $color-modal-separator;
    color: $color-gray-extended-7;
    font-family: $font-family-bold;
    font-size: 24px;
  }

  &__element {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 15px;

    &.flex-row {
      flex-direction: row;
      align-items: center;
    }

    button {
      border: 0;
      outline: 0;
      background-color: transparent;

      svg {
        height: 8px;
      }
    }

    &.copyid {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &__subtitle {
    font-family: $font-family-medium;
  }

  &.remove-last-border {

    .copyid {
      padding-right: 0;
      border: 0;
    }
  }

  &.partner-element {
    .edit-modal__element {
      max-width: 140px;
    }
  }

  &.custom-width {
    .edit-modal__title {
      width: 160px;
    }
  }
}
