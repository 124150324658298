.info-element {
  button {
    border: 0;
    outline: none;
    background: transparent;
  }

  &--multi {
    align-items: flex-end;
    padding-right: 20px;
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100px;
    margin: 0 20px;
    padding: 10px 0;
    border-bottom: 1px solid $color-gray-extended-8;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  svg {
    align-self: center;
    fill: $color-primary-white;
  }

  &-letterhead {
    margin: 0 10px 10px;

    svg {
      width: 14px;
      height: 11px;
      fill: $color-primary-white;
      stroke: $color-primary-white;

      &.minor {
        fill: $color-alarms-minor;
        stroke: $color-alarms-minor;
      }

      &.major {
        fill: $color-alarms-major;
        stroke: $color-alarms-major;
      }

      &.critical {
        fill: $color-secondary-orange;
        stroke: $color-secondary-orange;
      }
    }

    &--multi {
      width: 100%;
    }

    &__title {
      align-self: center;
      margin: 0 0 0 5px;
      color: $color-primary-white;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__id {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      color: $color-secondary-yellow;
      font-size: 10px;
      line-height: 12px;

      span {
        margin-right: 5px;
        color: $color-gray-extended-2;
        font-weight: bold;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;

    span {
      max-height: 44px;
      font-size: 14px;
      overflow: hidden;
    }
  }

  &__info {
    span {
      display: block;
      min-width: 135px;
      font-size: 10px;
      line-height: 12px;
    }

    &-updated {
      margin-top: 5px;
    }

    .info-element__date {
      color: $color-gray-extended-4;
    }

    &-title {
      color: $color-gray-extended-3;
    }
  }

  &-buttons {
    display: flex;
    margin-top: 10px;

    button {
      margin-right: 10px;
      padding: 0;

      svg {
        height: 14px;
      }
    }
  }
}

.alarms {
  .info-element-letterhead {
    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.alarms-multi {
  .info-element-letterhead {
    &__title {
      white-space: nowrap;
    }
  }
}
