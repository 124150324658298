.dropdown-alarms-clearbytype {
  position: absolute;
  top: 177px;
  right: 30px;
  width: 220px;
  height: auto;
  padding: 20px 15px;
  border: 1px solid #D8DADA;
  background: #FFF 0% 0% no-repeat padding-box;
  box-shadow: 0 0 10px #0003;
  z-index: map-get($z-index, dropdown);

  .dropdown__primary-button,
  .dropdown__secondary-button {
    display: flex;
    align-items: flex-end;
    height: auto;
    padding: 0;
    border: 0;
    background: 0;
    color: $color-secondary-blue-80;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
  }

  .dropdown__footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
  }

  .jobs-filter__button-bar {
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.alarms-clearbytype {
  &__form-section {
    display: inline-block;
    width: 175px;
  }

  &__label {
    padding-bottom: 8px;
    font-family: $font-family-bold;
  }

  &__button-bar {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
  }

  .selectbox {
    margin-bottom: 10px;

    &.light {
      .selectbox__header {
        line-height: normal;
      }

      ul.open {
        position: relative;
        top: 0;
      }
    }
  }

  .btn-primary {
    margin-right: 0;
    margin-left: 10px;
  }

  .btn-secondary {
    margin-right: 0;
    margin-left: 10px;
  }

}
