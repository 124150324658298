.filter-multi-select-box {
  position: relative;

  .input-label {
    display: block;
    margin-bottom: 6px;
    color: $color-primary-black;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 16px;
  }

  .input-wrapper {
    position: relative;

    input {
      width: 200px;
      height: 40px;
      padding-right: 25px;
      padding-left: 20px;
      border: 1px solid $color-gray-extended-2;
      border-bottom: 1px solid $color-primary-black;
      background-color: $color-primary-white;
      color: $color-primary-black;
      font-size: 14px;
      line-height: 16px;

      &.open {
        border: 1px solid $color-primary-black;
      }
    }

    .vertical-center {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .close-icon {
      top: 20px;
      right: 22px;
      border: none;
      background-color: transparent;

      svg {
        fill: $color-primary-black;
        cursor: pointer;
      }
    }

    .arrow-down {
      position: absolute;
      top: 17px;
      right: 10px;
      cursor: pointer;
    }

    .selected-text {
      left: 15px;
      color: $color-primary-black;
      font-size: 14px;

      .selected-highlight {
        font-family: $font-family-bold;
      }
    }

    svg {
      // Get rid of the extra space below the icon
      display: block;
    }
  }

  & .search-container {
    position: relative;
    width: 100%;
  }

  .overlay-box {
    position: absolute;
    width: 100%;
    z-index: 1001;

    &.sector-id {
      display: flex;
      position: absolute;
      bottom: 47px;
      flex-direction: column-reverse;
    }
  }
}
