.map {
  position: relative;

  &.cursor-gps {
    .map-container {
      cursor: crosshair;
    }
  }

  &.Map,
  &.Light {
    .map-container {
      background-color: $color-map-bg-light;
    }
  }

  &.Hybrid,
  &.Satellite {
    .map-container {
      background-color: $color-map-bg-hybrid;
    }
  }

  &.Dark {
    .map-container {
      background-color: $color-map-bg-dark;
    }
  }

  .map-search-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-color: $color-map-search-bg;
    z-index: map-get($z-index, map-search);

    .map-search-label {
      display: none;
      width: 70%;
      margin-bottom: 5px;
      color: $color-primary-white;
      font-family: $font-family-medium;
      font-size: 14px;
    }

    > svg {
      position: relative;
      right: 30px;
    }

    &.full-search {
      bottom: 0;
      flex-direction: column;
      height: 100%;

      > svg {
        position: absolute;
        top: 50%;
        right: calc(15% + 11px);
      }

      .map-search-label {
        display: block;
      }
    }

    > span {
      width: 70%;
      height: 40px;

      > button {
        display: none;
      }

      > input {
        width: 100%;
        height: 40px;
        padding-right: 40px;
        padding-left: 15px;
        border-top: 1px solid $color-gray-extended-2;
        border-right: 1px solid $color-gray-extended-2;
        border-bottom: 1px solid $color-primary-black;
        border-left: 1px solid $color-gray-extended-2;
        border-radius: 0;
        background-color: $color-primary-white;
        color: $color-primary-black;
        font-family: $font-family-roman;
        font-size: 14px;
        line-height: 40px;

        &::placeholder {
          color: $color-primary-black;
        }
      }

      .mq-dropdown-menu {
        margin: 0;
        border: 1px solid $color-primary-black;
        border-radius: 0;
        box-shadow: none;

        .mq-dataset-results {
          .mq-result {
            height: 40px;
            border-bottom: 1px solid $color-gray-extended-2;
            line-height: 40px;

            .mq-result-icon {
              display: none;
            }

            .mq-name,
            .mq-address {
              font-family: $font-family-roman;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .map-container {
    width: 100%;
    height: 100%;

    .leaflet-top {
      &.leaflet-right {
        margin-top: 15px;
      }
    }

    .leaflet-marker-pane {
      .leaflet-marker-icon {
        width: 8px;
        height: 8px;
        border: 1px solid $color-secondary-pink;
        border-radius: 8px;
        background-color: $color-primary-white;

        &:last-child {
          background: $color-secondary-pink;
        }
      }
    }

    .leaflet-overlay-pane {
      .leaflet-draw-guide-dash {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        will-change: transform;
      }
    }

    .leaflet-buttons-control-button {
      display: block;
      width: 30px;
      height: 30px;
      border: 0;
      background-clip: padding-box;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 18px 18px;
      cursor: pointer;
    }

    .leaflet-control-timelinelegend-container {
      &.leaflet-control {
        margin: 0 12px 5px 0;
        border: 1px solid $color-primary-white;
        border-radius: 4px;
        background: $color-primary-black;
        background-clip: padding-box;
        box-shadow: none;

        .leaflet-buttons-control-button {
          width: 28px;
          height: 28px;
          background-image: url('../../img/icons/schedule-legend.svg');
          background-position: 50% 50%;
        }
      }
    }

    .leaflet-control-timelinelegend {
      &--alerts-legend {
        &.leaflet-control {
          border: none;

          .leaflet-buttons-control-button {
            width: 30px;
            height: 30px;
            background-image: url('../../img/icons/map-control-alerts.svg');
            background-size: cover;
          }
        }
      }
    }

    .leaflet-control-polygondraw-container,
    .leaflet-control-multiselect-container,
    .leaflet-control-popup-container {
      &.leaflet-control {
        margin: 0 10px 5px 0;
        border: 0;
        border-radius: 17px;
        background: $color-primary-white;
        background-clip: padding-box;
        box-shadow: none;

        &.leaflet-control-toggled {
          background: $color-primary-red;

          .leaflet-control-polygondraw,
          .leaflet-control-polygondraw:hover {
            background-image: url('../../img/icons/polygon-tool-white.svg');
          }

          .leaflet-control-multiselect,
          .leaflet-control-multiselect:hover {
            background-image: url('../../img/icons/multiselect-tool-white.svg');
          }
        }

        .leaflet-control-polygondraw {
          background-image: url('../../img/icons/polygon-tool.svg');
          background-position: 60% 40%;

          &:hover {
            background-image: url('../../img/icons/polygon-tool-hover.svg');
          }

          &.disabled {
            background-image: url('../../img/icons/polygon-tool-disabled.svg');
            cursor: default;
          }
        }

        .leaflet-control-multiselect {
          background-image: url('../../img/icons/multiselect-tool.svg');
          background-position: 60% 60%;

          &:hover {
            background-image: url('../../img/icons/multiselect-tool-hover.svg');
          }

          &.disabled {
            background-image: url('../../img/icons/multiselect-tool-disabled.svg');
            cursor: default;
          }
        }

        .leaflet-control-popup {
          background-image: url('../../img/icons/popup-on.svg');
          background-position: 50% 60%;

          &:hover {
            background-image: url('../../img/icons/popup-on-hover.svg');
          }
        }

        &.leaflet-control-popup-off {
          .leaflet-control-popup {
            background-image: url('../../img/icons/popup-off.svg');
            background-position: 50% 60%;

            &:hover {
              background-image: url('../../img/icons/popup-off-hover.svg');
            }
          }
        }
      }
    }

    /* stylelint-disable no-duplicate-selectors */
    .leaflet-control-popup-container,
    .leaflet-control-multiselect-container,
    .leaflet-control-polygondraw-container {
      position: relative;

      .lf-tooltip {
        display: none;
        position: absolute;
        top: -5px;
        right: calc(100% + 12px);
        align-items: center;
        height: 38px;
        padding: 10px;
        border: 1px solid $color-gray-extended-2;
        background: $color-primary-white;
        white-space: nowrap;

        &:after,
        &:before {
          position: absolute;
          top: 50%;
          right: -22px;
          left: 100%;
          margin-top: 50%;
          transform: rotateY(180deg);
          border: solid transparent;
          content: '';
          pointer-events: none;
        }

        &:after {
          margin-top: -10px;
          margin-right: 3px;
          border-width: 10px;
          border-color: rgba(216, 218, 218, 0);
          border-right-color: $color-primary-white;
        }

        &:before {
          margin-top: -11px;
          margin-left: 0;
          border-width: 11px;
          border-color: rgba(216, 218, 218, 0);
          border-right-color: $color-gray-extended-2;
        }
      }

      &:hover {
        .lf-tooltip {
          display: flex;
        }
      }
    }

    .leaflet-control-multiselect-container {
      &.leaflet-control-toggled {
        .lf-tooltip {
          display: none;
        }
      }

      .disabled ~ .lf-tooltip {
        display: none;
      }
    }

    .leaflet-control-layers {
      &.leaflet-control {
        margin-bottom: 5px;
        border-radius: 17px;

        .leaflet-control-layers-toggle {
          width: 30px;
          height: 30px;
          border: 0;
          background-image: url('../../img/icons/layers.svg');
          background-size: 18px 18px;
        }
      }

      &.leaflet-control-layers-expanded {
        border-radius: 4px;
      }
    }

    .leaflet-control-attribution {
      display: none;
    }

    .leaflet-control-zoom {
      .leaflet-control-zoom-in {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .leaflet-control-zoom-out {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .leaflet-right {
      z-index: 400;

      .leaflet-control-zoom {
        margin-bottom: 20px;
      }
    }
  }

  .leaflet-timelinelegend-container {
    position: absolute;
    right: 47px;
    bottom: 0;

    &--alerts-legend {
      bottom: 40px;
    }

    .timelinelegend-container {
      display: flex;
      flex-direction: column;
      width: 155px;
      border: 1px solid $color-gray-extended-5;
      background: $color-gray-extended-7;
      color: $color-primary-white;

      .timelinelegend-section {
        padding: 10px;

        &:first-of-type {
          padding-bottom: 15px;
          border-bottom: 1px solid $color-gray-extended-5;
        }

        &:last-of-type {
          padding-top: 15px;
        }

        &__header {
          font-family: $font-family-bold;
          font-size: 14px;
        }

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          font-family: $font-family-roman;
          font-size: 14px;
        }
      }

      &:after,
      &:before {
        position: absolute;
        right: -19px;
        bottom: 73px;
        transform: rotateY(180deg);
        border: solid transparent;
        content: '';
        pointer-events: none;
      }

      &:after {
        margin-top: 1px;
        margin-right: 2px;
        border-width: 10px;
        border-right-color: $color-gray-extended-7;
      }

      &:before {
        border-width: 10px;
        border-right-color: $color-gray-extended-5;
      }
    }
  }

  .leaflet-multiselect-container {
    position: absolute;
    top: 21px;
    left: -170px;

    .multiselect-container {
      display: flex;
      flex-direction: column;
      width: 155px;
      border: 1px solid $color-gray-extended-5;
      background: $color-gray-extended-7;
      color: $color-primary-white;

      .multiselect-header {
        padding: 10px;
        border-bottom: 1px solid $color-gray-extended-5;

        span {
          font-family: $font-family-bold;
          font-size: 14px;
        }

        button {
          position: absolute;
          top: 13px;
          right: 10px;
          padding: 0;
          border: 0;
          outline: none;
          background-color: transparent;
          cursor: pointer;

          svg {
            width: 8px;
            height: 8px;
            fill: $color-primary-white;
          }
        }
      }

      .multiselect-action {
        border-bottom: 1px solid $color-gray-extended-8;
      }

      .multiselect-action,
      .multiselect-type {
        padding: 10px;

        span {
          font-family: $font-family-medium;
          font-size: 12px;
        }

        .radio-buttons {
          display: flex;
          flex-direction: column;

          &__element {
            color: $color-gray-extended-2;

            &:first-child {
              padding-top: 10px;
            }

            &:not(:last-child) {
              padding-bottom: 5px;
            }

            &-label {
              font-family: $font-family-roman;
              font-size: 14px;
            }
          }
        }
      }

      &:after,
      &:before {
        position: absolute;
        top: 10px;
        right: -19px;
        transform: rotateY(180deg);
        border: solid transparent;
        content: '';
        pointer-events: none;
      }

      &:after {
        margin-top: 1px;
        margin-right: 2px;
        border-width: 9px;
        border-right-color: $color-gray-extended-7;
      }

      &:before {
        border-width: 10px;
        border-right-color: $color-gray-extended-5;
      }
    }
  }

  .leaflet-popup {
    font-family: $font-family-roman;

    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      border: 1px solid $color-gray-extended-2;
      border-radius: 0;

      .leaflet-popup-content {
        display: flex;
        flex-direction: column;
        width: 322px;
        margin: 0;

        span {
          font-family: $font-family-roman;
          font-size: 12px;
          line-height: 12px;
        }

        .map-tooltip-label {
          color: $color-gray-extended-5;
          font-size: 12px;
          line-height: 14px;
        }

        .map-tooltip-top {
          padding: 10px;
          border-bottom: 1px solid $color-gray-extended-2;
          background: $color-gray-extended-1;

          .map-tooltip-nodeid {
            margin-left: 5px;
            font-family: $font-family-bold;
            font-size: 12px;
            line-height: 14px;
          }
        }

        .map-tooltip-bottom {
          display: flex;
          margin-top: 5px;
          margin-right: 10px;
          margin-left: 10px;

          &:last-child {
            margin-bottom: 10px;
          }

          .map-tooltip-label,
          .map-tooltip-data {
            font-size: 12px;
            line-height: 14px;
          }

          .map-tooltip-data {
            font-family: $font-family-medium;
          }

          .map-tooltip-label {
            align-self: center;
            width: 50%;
            font-family: $font-family-roman;
          }

          .map-tooltip-left,
          .map-tooltip-right {
            display: flex;
            flex-direction: column;
          }

          .map-tooltip-left {
            width: 175px;
            padding: 0 15px 10px 10px;
          }

          .map-tooltip-right {
            width: 100px;
            padding: 0 10px 10px 15px;
            border-left: 1px solid $color-gray-extended-2;
            background-color: $color-gray-extended-1;

            .map-tooltip-driverlevel {
              font-size: 14px;
            }

            .map-tooltip-lastreported {
              font-family: $font-family-roman;
            }
          }
        }
      }
    }

    .leaflet-popup-close-button {
      display: none;
    }
  }
}

.site-modal--map {
  .map {
    height: 485px;
    border: 1px solid $color-primary-black;

    > div {
      .leaflet-marker-pane {
        .leaflet-marker-icon {
          border-width: 2px;

          &.leaflet-poly-site {
            border: 1px solid $color-secondary-pink;
            background: $color-secondary-pink;
          }

          &.leaflet-poly-allsite {
            border: 1px solid $color-primary-red;
            background: $color-primary-red;
          }

          &.leaflet-poly-dragged {
            background: $color-primary-white;
          }
        }

        /* stylelint-disable declaration-no-important */

        .leaflet-marker-draggable {
          width: 10px !important;
          height: 10px !important;
          margin-top: -5px !important;
          margin-left: -5px !important;
          background: $color-secondary-pink;

          &[style*='opacity: 0.6'] {
            background: $color-primary-white;
          }
        }

        .leaflet-drag-target {
          background: $color-primary-white;
        }
      }

      .leaflet-poly-error-popup {
        .leaflet-popup-content-wrapper {
          padding: 0;
          border: 0;
          box-shadow: none;

          .leaflet-poly-error {
            padding: 5px 10px;
            background: $color-primary-red;
            color: $color-primary-white;
            font-size: 14px;
          }
        }

        .leaflet-popup-tip-container {
          display: none;
        }
      }
    }
  }

  &.map-error {
    .map {
      > div {
        .leaflet-marker-pane {
          .leaflet-marker-draggable {
            border: 2px solid $color-secondary-orange;
            background: $color-secondary-orange;

            &[style*='opacity: 0.6'] {
              background: $color-primary-white;
            }
          }
        }
      }
    }
  }
}
