$header-border-style: 1px solid $color-gray-extended-2;
$header-height: 50px;

.selectbox {
  display: flex;
  position: relative;
  border: $header-border-style;
  font-size: 14px;
  line-height: 40px;

  &__label {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: normal;

    &-required {
      color: $color-primary-red;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__header {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0;
    border: 0;
    background: $color-primary-white;
    text-align: start;
    cursor: pointer;

    &:after {
      display: inline-block;
      width: 0;
      height: 0;
      content: '';
      cursor: pointer;
    }

    span {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    > svg {
      margin-right: 0;
      margin-left: 15px;
      line-height: $header-height;
      stroke: $color-primary-black;
    }

    div > svg {
      margin-right: 15px;
      margin-left: 0;
    }

    .tooltip__element {
      &.left {
        margin-top: 9px;
      }
    }
  }

  ul {
    position: absolute;
    top: 100%;
    left: -1px;
    margin: 0;
    border: 1px solid $color-gray-extended-2;
    border-bottom: 0;
    background: $color-primary-white;
    color: $color-primary-black;
    z-index: map-get($z-index, selectbox);
    overflow-y: auto;

    &.open {
      display: block;
    }

    &.hide {
      display: none;
    }
  }

  li {
    border-bottom: 1px solid $color-gray-extended-2;
    font-size: 14px;
    cursor: pointer;

    div {
      display: block;
      padding: 0 0 0 20px;
      border-left: 4px solid transparent;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.selected {
        border-left: 4px solid $color-primary-red;
      }

      &:hover {
        border-left: 4px solid $color-primary-black;
      }
    }
  }

  &.dark,
  &.light {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 0;
    border: 0;

    .selectbox__header {
      padding: 0 25px 0 15px;
      border: 1px solid $color-gray-extended-5;
      border-bottom: 1px solid $color-gray-extended-2;
      background: $color-gray-extended-7;
      color: $color-gray-extended-2;
      line-height: 40px;

      svg {
        position: absolute;
        right: 10px;
        stroke: $color-gray-extended-2;
      }
    }

    ul {
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      border: 0;
      background: transparent;

      li {
        padding: 0;
        border-bottom: 1px solid $color-gray-extended-5;
        background: $color-gray-extended-7;
        color: $color-primary-white;
        line-height: 40px;

        div {
          padding: 0 0 0 11px;
          color: $color-gray-extended-2;

          &:hover {
            border-left: 4px solid $color-primary-white;
          }

          &.selected {
            border-left: 4px solid $color-primary-red;
          }
        }
      }

      .selectbox__loader {
        width: 195px;
        height: 160px;
        background-color: $color-gray-extended-5;
        color: $color-gray-extended-2;
      }
    }
  }

  &.light {
    .selectbox__header {
      width: 100%;
      border-color: $color-gray-extended-2;
      border-bottom-color: $color-primary-black;
      background: $color-primary-white;
      color: $color-primary-black;

      &--open {
        border-color: $color-primary-black;
      }

      svg {
        stroke: $color-primary-black;
        fill: $color-primary-black;
      }
    }

    .selectbox__label {
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }

    ul {
      min-width: 100%;
      overflow-x: hidden;
      border: 1px solid $color-primary-black;
      border-top: 0;

      li {
        border-color: $color-gray-extended-2;
        background: transparent;

        div {
          color: $color-primary-black;

          &:hover {
            border-left: 4px solid $color-primary-black;
          }

          &.selected {
            border-left: 4px solid $color-primary-red;
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  &.error {
    .selectbox__header {
      border: 1px solid $color-secondary-orange;
      border-bottom: 4px solid $color-secondary-orange;
    }

    .error-msg {
      color: $color-secondary-orange;
      font-family: $font-family-roman;
      font-size: 10px;
      line-height: 12px;
      grid-column: 2;
    }
  }

  &.horizontal {
    display: grid;

    .selectbox__label {
      display: flex;
      align-self: center;
      width: 100%;
      grid-column: 1;
    }

    .selectbox__header {
      margin-right: 0;
      margin-left: auto;
      grid-column: 2;
    }

    .error-msg {
      margin-left: auto;
    }

    ul {
      min-width: auto;
      margin-left: auto;
      grid-column: 2;
    }
  }

  &.disabled {
    .selectbox__header {
      border-color: $color-gray-extended-6;
      border-bottom-color: $color-gray-extended-6;
      color: $color-gray-extended-6;
      cursor: not-allowed;

      svg {
        stroke: $color-gray-extended-6;
      }
    }
  }
}
