.dimmingpoints-luxonoff {
  width: 262px;
  height: 120px;
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 6px;
  padding-left: 20px;
  border: 1px solid $color-gray-extended-2;

  &__title {
    margin-bottom: 10px;
    font-family: $font-family-medium;
    font-size: 16px;
    line-height: 18px;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-family: $font-family-roman;
    font-size: 12px;
    line-height: 14px;

    .bold {
      font-family: $font-family-medium;
    }
  }

  &__fields {
    display: flex;
    align-items: center;

    .textbox {
      width: 100px;
      margin-right:20px;
    }
    .textbox:not(:last-child) {
      margin-bottom:0px;
    }
    .textbox label {
      font-size:12px;
    }
  }

  &__notification {
    margin-top: 10px;

    &-title {
      margin-bottom: 10px;
      font-family: $font-family-medium;
      font-size: 12px;
      line-height: 14px;
    }

    &-content {
      display: flex;
      position: relative;
      justify-content: baseline;
      margin-bottom: 4px;

      .tooltip {
        width: 20px;
        height: 20px;
      }

      &-btn {
        position: relative;
        width: 20px;
        height: 20px;
        padding: 0;
        border: 0;
        border-radius: 20px;
        outline: 0;
        background: $color-secondary-blue;

        svg {
          fill: $color-primary-white;
        }

        &.photocell {
          background: $color-secondary-blue;

          svg {
            fill: $color-secondary-blue;
          }
        }

        &.fixed {
          background: $color-secondary-yellow;

          svg {
            fill: $color-secondary-yellow;
          }
        }

        &.selected {
          &:before {
            position: absolute;
            top: -3px;
            left: -3px;
            width: 26px;
            height: 26px;
            border: 1px solid $color-secondary-blue;
            border-radius: 50%;
            content: '';
          }

          &.fixed {
            &:before {
              border: 1px solid $color-secondary-yellow;
            }
          }
        }

        &[disabled] {
          cursor: not-allowed;
          opacity: .4;
        }
      }

      span {
        display: flex;
        align-self: center;
        margin-left: 10px;
        font-family: $font-family-roman;
        font-size: 12px;
        line-height: 14px;
      }

      .timeline__bg-tooltip  {
        display: none;
        top: -12px;
      }

      &:hover {
        .timeline__bg-tooltip {
          display: block;
        }
      }
    }
  }

  &.readonly {
    margin-top: 0;
    border: 0;

    .dimmingpoints-setter__notification-content-btn {
      cursor: default;
    }
  }
}
