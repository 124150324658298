.radio-buttons {
  display: flex;
  justify-content: space-between;

  &__element {
    display: flex;
    cursor: pointer;

    div {
      display: flex;
      align-self: center;
    }

    &-label {
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &.dark {
    .radio-buttons__element {
      &-circle {
        position: relative;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border: 1px solid $color-gray-extended-2;
        border-radius: 50%;
        background: $color-gray-extended-7;

        &:after {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 8px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: $color-gray-extended-2;
          content: '';
        }

        &.selected {
          &:after {
            display: inline-block;
          }
        }
      }
    }
  }

  &.light {
    .radio-buttons__element {
      &-circle {
        position: relative;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border: 1px solid $color-primary-black;
        border-radius: 50%;
        background: $color-primary-white;

        &:after {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 8px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: $color-primary-black;
          content: '';
        }

        &.selected {
          &:after {
            display: inline-block;
          }
        }
      }
    }
  }

  &.vertical {
    flex-direction: column;
    justify-content: flex-start;

    .radio-buttons__element {
      margin-bottom: 15px;

      &-label {
        font-family: $font-family-roman;
      }
    }
  }
}
