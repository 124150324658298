﻿/*
 * SVG Progress Indicator
 *
 * Set-up the indicator using SVG attributes that are
 * supported as properties in CSS.
 *
 * @since 07/07/22
 * @link  https://codepen.io/_rahul/pen/BarobwE
 */
/* Animated spinner version */
/* Spinner animation */
@keyframes spinner {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.svg-pi-wrapper {
	position: relative;
}
.svg-pi {
	transform: rotate(-90deg);
}
.svg-pi-indicator {
  transition: stroke-dashoffset 0.4s linear;
}
.svg-pi-indicator--spinner {
	animation: spinner .75s linear infinite;
	transform-origin: center;
}
.svg-pi-label {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
.svg-pi-label__loading {
	opacity: .8;
	font-size: 1em;
	display: block;
}
.svg-pi-label__progress {
	font-size: 2.2em;
	font-weight: bold;
	display: block;
}
