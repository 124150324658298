$toolbar-icon-height: 14px;

$icon-width: 40px;
$icons-width: 7 * $icon-width;
$city-width: 95px;
$city-margin: 35px;
$state-width: 95px;
$state-margin: 35px;
$actions-width: 95px;
$actions-margin: 2px;
$other-margin: 15px;
$table-margins: 60px;
$scrollbar-width: 7px;

$fix-table-widths: calc(#{$icons-width} + #{$city-width} + #{$city-margin} + #{$state-width} + #{$state-margin} + (3 * #{$actions-width}) + (3 * #{$actions-margin}) + (3 * #{$other-margin}));
$other-width: calc((100vw - #{$fix-table-widths} - #{$table-margins} - #{$scrollbar-width}) / 3);

.site-selector {
  display: flex;
  flex-direction: column;

  .toolbar-tabs {
    &__container {
      &-tabset {
        width: auto;

        &-btn {
          cursor: default;

          > span {
            cursor: default;
          }
        }

        &-btn-content {
          svg {
            width: 14px;
            height: 14px;
            margin-top: 0;
            margin-right: 5px;
            fill: $color-secondary-yellow;
          }

          &-subtitle {
            font-size: 12px;
          }
        }
      }

      &-title {
        margin-right: 85px;
      }
    }
  }

  .toolbar__icon {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 60px;
    border-right: 1px solid $color-gray-extended-5;
    border-left: 1px solid $color-gray-extended-5;
  }

  .toolbar__icon-container {
    height: 100%;
    padding: calc((#{$toolbar-height} - #{$toolbar-icon-height}) / 2);
    cursor: pointer;

    svg {
      height: $toolbar-icon-height;
      fill: $color-primary-white;
    }
  }

  .content {
    flex-direction: column;
    height: calc(100vh - 141px);
    padding-bottom: 55px;
    background-color: $color-gray-extended-1;

    .no-sites-for-user {
      margin-top: 30px;
      font-size: 24px;
      text-align: center;
    }

  }

  &__dropdown {
    position: absolute;
    top: 80px;
    left: 0;
    width: 260px;
    height: 97px;
    padding: 15px 20px 20px;
    z-index: map-get($z-index, 'dropdown');

    .textbox {
      position: relative;

      &.dark {
        .textbox__input {
          border-bottom: 1px solid $color-gray-extended-2;

          &::placeholder {
            color: $color-gray-extended-4;
          }
        }

        .textbox__input--icon {
          padding: 0 30px 0 15px;
        }

        .textbox__icon {
          top: 10px;
          right: 10px;

          svg {
            cursor: pointer;
            fill: $color-gray-extended-2;
          }
        }
      }
    }

    .dropdown__title {
      font-size: 14px;
      line-height: 16px;

      &.space-bottom {
        margin-bottom: 5px;
      }
    }
  }

  &__header {
    display: flex;
    width: calc(1300px - 30px);
    height: 30px;
    margin-bottom: 5px;
    margin-left: 30px;
    color: $color-gray-extended-4;
    font-size: 10px;

    &-datas {
      display: flex;
      align-items: center;
    }

    &-cell {
      display: flex;
      align-items: flex-end;
      width: 95px;
      height: 30px;
      margin-right: 35px;

      &--text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
        padding-bottom: 7px;

        svg {
          margin-right: 5px;
        }
      }

      .textbox {
        position: relative;
        flex-direction: row;
        width: 100%;
        margin-right: 20px;

        &__input {
          position: relative;
          bottom: 2px;
          height: 30px;
          padding-top: 14px;
          padding-left: 0;
          border: 0;
          border-bottom: 1px solid $color-primary-black;
          background-color: transparent;
          color: $color-gray-extended-4;
          font-size: 10px;

          &::placeholder {
            position: absolute;
            bottom: 2px;
            color: $color-primary-black;
          }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          height: 25px;
          padding-top: 8px;
          transition: background-color 5000s ease-in-out 0s;
          border: none;
          border-bottom: 1px solid $color-primary-black;
          font-size: 10px;
          -webkit-text-fill-color: $color-primary-black;
          -webkit-box-shadow: 0 0 0 1000px transparent inset;
          box-shadow: 0 0 0 1000px transparent inset;

          &:-webkit-autofill:first-line {
            font-family: $font-family-roman;
            font-size: 10px;
          }
        }

        svg {
          width: 12px;
          height: 12px;
          cursor: pointer;
        }

        &__icon {
          position: absolute;
          top: auto;
          right: 0;
          bottom: 2px;

          svg {
            width: 8px;
            height: 8px;
            margin-bottom: 2px;
          }
        }
      }

      &--name {
        padding-left: 15px;
      }

      &--name,
      &--account,
      &--partner {
        width: $other-width;
        margin-right: $other-margin;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .site-selector__sort {
        width: 95px;
        margin-right: 2px;
        padding-bottom: 5px;
        padding-left: 15px;
        cursor: pointer;

        &.asc {
          .up-arrow {
            fill: $color-primary-red;
          }
        }

        &.desc {
          .down-arrow {
            fill: $color-primary-red;
          }
        }
      }

      svg {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }

      div {
        display: flex;
        align-items: flex-end;
        height: 30px;
      }
    }
  }

  &__list-container {
    flex: 1 1 auto;
    width: calc(100% - 30px);
    max-height: calc(100vh - 226px);

    &--filter {
      margin-left: 20px;
      padding: 10px;
      width: 300px;

      &-val{
        color: $color-secondary-yellow;
        font-family: $font-family-bold;
      }

      &-text{
        font-size: 14px;
      }
    }
  }

  &__row {
    display: flex;
    width: calc(100% - 30px);
    height: 38px;
    margin-bottom: 5px;
    margin-left: 30px;
    border: 1px solid $screen-background-1;
    border-left: 3px solid transparent;
    background-color: $color-primary-white;
    font-size: 12px;

    &:hover {
      border: 1px solid $color-gray-extended-2;
      border-left: 3px solid $color-gray-extended-3;
      box-shadow: 0 2px 5px $color-list-shadow-box;
      cursor: pointer;

      .site-selector__icons {
        svg {
          fill: $color-primary-black;
        }
      }
    }

    &--selected {
      border-left: 3px solid $color-primary-red;
      box-shadow: 0 2px 5px $color-list-shadow-box;

      &:hover {
        border-left: 3px solid $color-primary-red;
      }

      .site-selector__icons {
        svg {
          fill: $color-primary-black;
        }
      }

      .site-selector__actions {
        &-nodes {
          background-color: $color-gray-extended-2;
        }

        &-added {
          background-color: $color-table-green-darker;
        }

        &-attn {
          background-color: $color-table-orange-darker;
        }
      }
    }
  }

  &__site {
    &-datas {
      display: flex;
      align-items: center;
    }

    &-name {
      width: $other-width;
      margin-right: $other-margin;
      padding-left: 15px;
      font-family: $font-family-medium;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-city,
    &-state,
    &-account,
    &-partner {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-city {
      width: $city-width;
      margin-right: $city-margin;
    }

    &-state {
      width: $state-width;
      margin-right: $state-margin;
    }

    &-account,
    &-partner {
      width: $other-width;
      margin-right: $other-margin;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: $font-family-medium;

    > div {
      width: $actions-width;
      height: 34px;
      margin-right: $actions-margin;
      padding: 12px 0 0 15px;
    }

    &-nodes {
      background-color: $screen-background-1;
    }

    &-added {
      background-color: $color-table-green;
    }

    &-attn {
      background-color: $color-table-orange;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    width: $icons-width;

    &-link {
      display: flex;
      align-items: center;
      width: $icon-width;
      border-right: 1px solid $color-gray-extended-2;

      svg {
        width: 18px;
        height: 18px;
        margin: auto;
        fill: $color-gray-extended-2;
      }

      &.last {
        border-right: 0;
      }
    }
  }
}
