.btn {
  outline: none;
  font-family: $font-family-bold;
  cursor: pointer;

  &-small {
    height: 20px;
    padding: 0 10px;
    font-size: 10px;
    line-height: 12px;
  }

  &-medium {
    height: 30px;
    padding: 0 15px;
    font-size: 12px;
    line-height: 14px;
  }

  &-large {
    height: 40px;
    padding: 0 25px;
    font-size: 14px;
    line-height: 16px;
  }

  &-circle {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
  }

  &-primary {
    margin: 0;
    border: 1px solid $color-primary-white;
    border-radius: 30px;
    background-color: $color-primary-black;
    color: $color-primary-white;
    box-shadow: none;
  }

  &-secondary {
    margin: 0 10px 0 0;
    border: 1px solid $color-primary-black;
    border-radius: 30px;
    background-color: $color-primary-white;
    color: $color-primary-black;
    box-shadow: none;
  }

  &-textlink {
    height: 15px;
    padding: 0;
    border: 0;
    border-bottom: 1px solid $color-secondary-blue;
    background-color: transparent;
    color: $color-secondary-blue;
  }

  &-icon {
    border: 0;
    border-radius: 50%;
    background: $color-primary-white;

    svg {
      margin: auto;
      fill: $color-primary-black;
    }

    &.disabled {
      svg {
        fill: $color-gray-extended-2;
      }
    }
  }

  &-none {
    height: auto;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}

button:disabled {
  cursor: not-allowed;
  opacity: .4;
}
