.fixtures {
  .fixture-name {
    display: flex;
    align-items: center;
    height: 40px;
    color: $color-secondary-blue-80;
    text-decoration: underline;
    cursor: pointer;
    overflow: hidden;

    &-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .tooltip {
      display: flex;
      align-items: center;
      height: 40px;

      svg {
        width: 14px;
        min-width: 14px;
        height: 14px;
        cursor: pointer;
      }

      &__element {
        top: 7px;
      }
    }
  }

  .fixtures-download {
    border: 0;
    outline: 0;
    background: transparent;

    svg {
      width: 9px;
      height: 10px;
    }
  }
}

.fixture-title {
  margin-bottom: 20px;
  font-family: $font-family-bold;
  font-size: 24px;
  position:absolute;
}

.fixture-details {
  margin-bottom: 20px;
  font-family: $font-family-bold;
  font-size: 18px;
}

.powerdraw-details {
  font-family: $font-family-bold;
  font-size: 18px;
  margin-bottom: 7px;
}

.fixture-edit {
  cursor: pointer;
}
