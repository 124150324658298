/* stylelint-disable max-nesting-depth */

.modal {
  display: flex;
  position: fixed;
  top: 50px;
  left: 50%;
  flex-direction: column;
  max-width: 100%;
  padding: 30px 20px;
  background-color: $color-primary-white;
  overflow: auto;
  z-index: map-get($z-index, modal);
  scrollbar-gutter: stable;

  &.padding-30 {
    padding: 30px;
  }

  .modal__content.modal__content-has-table{
    overflow: auto;
  }

  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    font-size: 17px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 25px;
    color: $color-primary-black;
    font-family: $font-family-bold;
    font-size: 24px;
  }

  &__subtitle {
    margin-bottom: 25px;
    color: $color-gray-extended-4;
    font-size: 12px;
  }

  &__content {
    display: flex;

    &-has-table {
      display: block;
      height: 100%;
      overflow: hidden;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__backdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $backdrop-modal;
    z-index: map-get($z-index, modal-backdrop);
  }

  &.changepassword-mfa {
    .modal__content {
      flex-direction: column;

      span {
        margin-bottom: 20px;
      }
    }
  }

  &.changepassword {
    padding: 30px;

    .modal__content {
      &-left {
        width: 200px;

        .textbox {
          margin-bottom: 15px;

          &.error {
            margin-bottom: 0;
          }
        }
      }

      &-right {
        margin-left: 50px;

        .password-req-label {
          margin-bottom: 10px;
          font-family: $font-family-medium;
          font-size: 14px;
        }

        .password-req {
          display: flex;
          flex-direction: column;

          span {
            display: inline-block;
            margin-top: 10px;
            font-size: 10px;

            &.password-req-error {
              color: $color-secondary-orange;
            }

            &.password-req-ok:before,
            &.password-req-error:before,
            &.password-req-none:before {
              display: inline-block;
              position: relative;
              top: 1px;
              width: 10px;
              height: 10px;
              margin-right: 10px;
            }

            &.password-req-none:before {
              content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><g fill="%23fff" stroke="%23d8dada" stroke-miterlimit="10" stroke-width="2"><circle cx="5" cy="5" r="5" stroke="none"/><circle cx="5" cy="5" r="4" fill="none"/></g></svg>');
            }

            &.password-req-ok:before {
              content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><g fill="%2300ac3e" stroke="%2300ac3e" stroke-miterlimit="10" stroke-width="2"><circle cx="5" cy="5" r="5" stroke="none"/><circle cx="5" cy="5" r="4" fill="none"/></g></svg>');
            }

            &.password-req-error:before {
              content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><g fill="%23fff" stroke="%23ed7000" stroke-miterlimit="10" stroke-width="2"><circle cx="5" cy="5" r="5" stroke="none"/><circle cx="5" cy="5" r="4" fill="none"/></g></svg>');
            }
          }
        }
      }
    }

    .modal__footer {
      margin: 0;
    }
  }

  &.dismiss-alert-modal {
    color: $color-primary-black;
  }

  &.success-modal {
    color: $color-primary-black;
    font-size: 14px;
    line-height: 16px;

    .modal__title {
      font-size: 20px;
      line-height: 24px;
    }

    .modal__content {
      svg {
        width: 30px;
        height: 24px;
        margin-right: 10px;
        fill: $color-secondary-green;
      }

      div {
        margin-bottom: 20px;
      }

      div:last-child {
        margin-bottom: 0;
      }
    }

    .modal__footer {
      margin-top: auto;
    }
  }

  .modal-divided {
    display: flex;

    &__sidebar {
      display: flex;
      flex-direction: row;
      margin-right: 30px;
    }

    &__content {
      width: 309px;

      &-comment {
        color: $color-gray-extended-4;
        font-family: $font-family-roman;
        font-size: 12px;
        line-height: 14px;

        &.footer-comment {
          margin-top: 10px;
        }
      }

      &-data {
        margin-top: 15px;

        .selectbox {
          margin-top: 15px;
        }
      }
    }

    &__info {
      display: flex;
      margin-top: 20px;
      color: $color-gray-extended-7;
      font-size: 12px;
      line-height: 14px;

      svg {
        width: 24px;
        margin-right: 6px;
        font-size: 24px;
      }

      &.info {
        margin-top: 30px;
        fill: $color-secondary-blue;
      }

      &.warning {
        fill: $color-secondary-orange;
      }

      .bold {
        font-family: $font-family-medium;
      }
    }
  }

  &.download-list {
    color: $color-primary-black;

    .modal__title {
      margin-bottom: 25px;
    }

    .download-list__subtitle {
      margin-bottom: 15px;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }

    .radio-buttons__element {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.list-uploaded {
    padding: 30px;
    border-top: 5px solid $color-secondary-green;
    color: $color-primary-black;
    font-size: 14px;
    line-height: 16px;

    .modal__content {
      overflow: hidden;
    }

    .modal__title {
      margin-bottom: 25px;
    }

    .list-uploaded__success,
    .list-uploaded__warning {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $color-gray-extended-2;

      &--bold {
        font-family: $font-family-medium;
      }

      svg {
        margin-right: 5px;
        fill: $color-secondary-green;
      }
    }

    .list-uploaded__warning {
      border-bottom: 0;

      svg {
        fill: $color-secondary-orange;
      }
    }

    .list-uploaded__id-list {
      display: flex;
      flex-direction: column;
      height: 170px;
      padding: 5px 10px;
      border: 1px solid $color-gray-extended-2;
      border-bottom: 1px solid $color-primary-black;
      color: $color-primary-black;
      font-size: 12px;
      line-height: 20px;
      cursor: default;
      overflow: auto;

      span {
        display: block;
      }
    }
  }

  .btn-none {
    display: flex;
    justify-content: flex-end;
    height: 14px;
    margin-bottom: 5px;
    font-size: 12px;

    svg {
      height: 12px;
      margin-left: 5px;
      fill: $color-primary-black;
    }
  }

  &.modal-bordertop-orange {
    border-top: 5px solid $color-secondary-orange;
  }

  &.modal-bordertop-yellow {
    border-top: 5px solid $color-secondary-yellow;
  }

  &.modal-bordertop-green {
    border-top: 5px solid $color-secondary-green;
  }

  &.modal-bordertop-blue {
    border-top: 5px solid $color-secondary-blue;
  }

  &--full-screen {
    display: flex;
    top: 0;
    left: 0;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0;
    transform: unset;

    .modal {
      &__close-button {
        display: none;
      }

      &__footer {
        display: none;
      }

      &__content {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-height: 750px){
      max-height: 80vh;
  }
}

.group-interactions {
  .modal {
    padding: 30px;

    &__primary-button {
      margin-left: 10px;
    }

    &--full-screen {
      padding: 0;
    }

    .radio-buttons.vertical .radio-buttons__element {
      margin-bottom: 10px;
    }
  }
}

.analytics-interactions {
  .modal-divided {
    display: flex;
    margin-top: 40px;

    &__leftcontent {
      width: 535px;
      padding: 20px;
      background: $color-gray-extended-1;

      > div:first-of-type {
        margin-top: 0;

        .textbox:first-of-type,
        .selectbox:first-of-type {
          margin-top: 0;
        }
      }
      .textbox {
        flex-direction: row;
        margin: 8px 0;

        label {
          width: 160px;
          line-height: 20px;
        }

        .textbox__input {
          height: 20px;
        }
      }
    }
    .viewReport-title {
      margin-bottom: 25px;
      font-family: $font-family-bold;
      font-size: 24px;
    }
  }
  div.modal {
    padding: 0px 30px 30px 30px;

    &__primary-button {
      margin-left: 10px;
    }

    div.modal__content {
      padding-bottom: 0;
    }

  }
}

.create-edit-report-definition {
   display: block;
}

.fixture-interactions {

  .typeWattageWrapper,
  .manufacturerSkuWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > div:first-of-type {
      margin-left: 0;
    }

    .textbox {
      width: 205px;
      margin-left: 28px;
      margin-top: 0 !important;
      margin-bottom: 0;
    }

    .selectbox {
      width: 205px;
    }

    .wattageRatingUnits {
      position: relative;
      right: 20px;
      font-size: 14px;
      top: 12px;
    }

  }

  .modal-divided {
    display: flex;
    margin-top: 40px;

    &__leftcontent {
      width: 450px;
      padding: 20px;
      background: $color-gray-extended-1;

      > div:first-of-type {
        margin-top: 0;

        .textbox:first-of-type,
        .selectbox:first-of-type {
          margin-top: 0;
        }
      }

      .selectbox ul {
        min-width: auto;
      }

      &-data {
        margin-top: 15px;
      }

      .nemaSocketWrapper {
        color: $color-primary-black;
        font-family: $font-family-medium;
        font-size: 14px;

        .radio-buttons {
          flex-direction: row;
          padding-top: 10px;
        }

        div.second-line {
          margin-left: 15px;
          margin-top: 5px;
        }

      }
    }

    &__rightcontent {
      width: 260px;
      padding-right: 12px;

      .powerDrawSection {
        padding: 20px;
        background-color:#333;
        color:#FFF;
      }

      .powerDrawMargin {
        background-color:#999;
        color: #000;
        height: 90px;
        padding: 12px 20px;
      }

        .textbox {
          margin-top: 0px !important;
          width: 120px
        }

      .powerDrawWrapper {
        display: flex;
        align-items: center;
        height: 36px;
        margin-bottom: 2px;
        padding-bottom: 0px;

        &.no-border {
          padding: 0;
          border: 0;
        }

        .powerDrawHeading1 {
          width: 20%;
          margin-right: 40px;
          font-family: $font-family-bold;
          font-size: 13px;
        }

        .powerDrawHeading2,
        .powerDrawHeading3 {
          width: 40%;
          font-family: $font-family-bold;
          font-size: 13px;
        }

        .powerDrawtitle {
          width: 21%;
          margin-right:44px;
          font-family: $font-family-bold;
          font-size: 16px;
          line-height: 28px;
        }

        .textbox {
          width: 60%;
          margin: 0 !important;
          margin-left: 10px;
        }

        .powerDrawUnits {
          position: relative;
          right: 23px;
          font-size: 14px;

          &.error {
            bottom: 8px;
          }
        }

      }

      &-data {
        margin-top: 15px;
      }

      .chart {
        width: 450px;
        height: 300px;
        margin: 0;
        padding-top: 25px;
        padding-left: 30px;

        /* stylelint-disable declaration-no-important */
        canvas {
          width: 435px !important;
          height: 275px !important;
        }
      }

      .powerdraw-chart {
        width: 530px;
        height: 300px;
        margin-top: -25px;
        margin-left: -55px;
      }
    }
  }

  div.modal {
    padding: 0;

    &__primary-button {
      margin-left: 10px;
    }

    div.modal__content {
      padding-bottom: 0;
    }

  }

  &.create-fixture {
    .nemaSocketWrapper {
      margin-bottom: 5px;
    }

    .modal__footer {
      bottom: 30px;
    }
  }

  &.view-fixture {
    .modal__content {
      height: 100%;

      .modal-divided {
        height: 100%;
        margin-top: 0px;

        .viewFixture-title {
          margin-bottom: 25px;
          font-family: $font-family-bold;
          font-size: 24px;
        }

        .modal-divided__leftcontent {
          width: 260px;
          padding: 30px;
          border-right: 1px solid $color-gray-extended-2;
          background: $color-gray-extended-1;

          .textbox {
            flex-direction: row;
            margin: 8px 0;

            label {
              width: 160px;
              line-height: 20px;
            }

            .textbox__input {
              height: 20px;
            }
          }
        }

        .modal-divided__rightcontent {
          padding: 30px;

          .isProfileMissing {
            position: absolute;
            top: 190px;
            left: 424px;
            padding: 15px;
            border: 1px solid $color-gray-extended-2;
            background-color: $color-primary-white;
            color: $color-gray-extended-2;
            font-size: 14px;
            line-height: 12px;
          }

          .minMaxWrapperText {
            position: absolute;
            top: 80px;
            right: 130px;
            color: $color-gray-extended-7;
            font-size: 12px;

            .minWrapper {
              padding-right: 12px;
              float: left;
              font-weight: bold;

              .minColor {
                position: relative;
                top: 2px;
                width: 8px;
                height: 8px;
                margin-right: 5px;
                float: left;
                border: 1px solid $color-secondary-blue-40;
                border-radius: 50%;
                background-color: $color-secondary-blue-40;
              }
            }

            .maxWrapper {
              float: left;
              font-weight: bold;

              .maxColor {
                position: relative;
                top: 2px;
                width: 8px;
                height: 8px;
                margin-right: 5px;
                float: left;
                border: 1px solid $color-secondary-blue;
                border-radius: 50%;
                background-color: $color-secondary-blue;
              }
            }

          }
        }

      }
    }
  }

  &.copy-fixture {
    display: flex;
    flex-direction: column;

    .copyFixture-title {
      margin-bottom: 25px;
      font-family: $font-family-bold;
      font-size: 24px;
    }

    .modal {
      padding: 30px;
    }

    .modal-content-data {
      width: 100%;

      .selectbox {
        width: 300px;
        margin-top: 15px;

        ul {
          position: relative;
          top: 0;
          border: 0;
        }

        .selectbox__header span {
          text-overflow: ellipsis;
          overflow: hidden;
        }

      }

      .ReactVirtualized__List {
        position: relative;
        border: 1px solid $color-primary-black;
        overflow: auto;

        .ReactVirtualized__Grid__innerScrollContainer {
          max-width: 300px;
        }

        .fixtures-filter__list-row {
          display: flex;
          align-items: center;
          padding: 0 10px;
          border-bottom: 1px solid $color-gray-extended-2;
          background-color: $color-primary-white;
          line-height: 40px;

          .fixturesListRenderer {
            display: flex;
            align-items: center;
            width: 100%;

            .checkbox {
              margin-right: 10px;

              .checkbox__button {
                border: 1px solid $color-primary-black;
                background: $color-primary-white;

                svg {
                  fill: $color-primary-black;
                }

              }
            }
          }

        }

      }

    }

    .modal__footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  @media (max-height: 1000px){
    .modal {
      max-height: 80vh;
    }
  }
}

.user-interactions {
  .access-status {
    margin: 20px 0;
    background: $color-gray-extended-1;
    pointer-events: none;

    span {
      display: inline-block;
      margin: 10px 0;
      margin-bottom: 11px;
      color: $color-primary-black;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
    }

    .radio-buttons {
      flex-direction: row !important;
      width: 50%;

      .radio-buttons__element {
        width: 50%;
      }
    }
  }

  .modal-divided {
    display: flex;

    &__radio {
      display: flex;
      flex-direction: row;
      margin-right: 30px;
    }

    &__leftcontent {
      width: 300px;

      > div:first-of-type {
        margin-top: 0;

        .textbox:first-of-type,
        .selectbox:first-of-type {
          margin-top: 0;
        }
      }

      &-comment {
        color: $color-gray-extended-4;
        font-family: $font-family-roman;
        font-size: 12px;
        line-height: 14px;

        &.footer-comment {
          margin-top: 10px;
        }
      }

      &-data {
        margin-top: 15px;

        .selectbox {
          margin-top: 15px;
        }
      }
    }

    &__rightcontent {
      width: 300px;
      margin-left: 50px;

      > div:first-of-type {
        margin-top: 0;
      }

      &-comment {
        color: $color-gray-extended-4;
        font-family: $font-family-roman;
        font-size: 12px;
        line-height: 14px;

        &.footer-comment {
          margin-top: 10px;
        }
      }

      &-data {
        margin-top: 15px;

        .selectbox {
          margin-top: 15px;
        }
      }
    }

    &.enduser {
      flex-direction: column;

      .modal-divided__rightcontent {
        margin-left: 0;

        > div:first-of-type {
          margin-top: 20px;
        }
      }

      .modal-divided__leftcontent,
      .modal-divided__rightcontent {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }

    &__info {
      display: flex;
      margin-top: 20px;
      color: $color-gray-extended-7;
      font-size: 12px;
      line-height: 14px;

      svg {
        width: 24px;
        margin-right: 6px;
        font-size: 24px;
      }

      &.info {
        margin-top: 30px;
        fill: $color-secondary-blue;
      }

      &.warning {
        fill: $color-secondary-orange;
      }

      .bold {
        font-family: $font-family-medium;
      }
    }
  }

  .modal {
    padding: 30px;

    &__primary-button {
      margin-left: 10px;
    }
  }
}
