.dropdown-date-period-selector {
  position: absolute;
  top: 80px;
  width: 100%;
  padding: 25px;
  z-index: map-get($z-index, dropdown);

  .btn-primary {
    margin-right: 10px;
  }

  .btn-secondary {
    margin-right: 0;
  }

  .dropdown__footer {
    justify-content: flex-end;
  }

  .dropdown__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .react-datepicker-container {
    width: 100%;

    input {
      width: 100%;
    }

    .react-datepicker {
      width: 100%;
    }
  }
}
