$font-family-bold: 'Neue Haas Grotesk W01 Display 75 Bold';
$font-family-medium: 'Neue Haas Grotesk W01 Display 65 Medium';
$font-family-roman: 'Neue Haas Grotesk W01 Display 55 Roman';
$font-family-text-roman: 'Neue Haas Grotesk W01 Text 55 Roman';
$font-base: $font-family-roman, sans-serif;

@font-face {
  font-family: $font-family-bold;
  font-display: block;
  src: url('../../font/NHaasGroteskDSW01-75Bd.woff2') format('woff2'),
  url('../../font/NHaasGroteskDSW01-75Bd.woff') format('woff');
}

@font-face {
  font-family: $font-family-medium;
  font-display: block;
  src: url('../../font/NHaasGroteskDSW01-65Md.woff2') format('woff2'),
  url('../../font/NHaasGroteskDSW01-65Md.woff') format('woff');
}

@font-face {
  font-family: $font-family-roman;
  font-display: block;
  src: url('../../font/NeueHaasGroteskDisp-W01.woff2') format('woff2'),
  url('../../font/NeueHaasGroteskDisp-W01.woff') format('woff');
}

@font-face {
  font-family: $font-family-text-roman;
  font-display: block;
  src: url('../../font/NHaasGroteskTXStd-55Rg.woff2') format('woff2'),
  url('../../font/NHaasGroteskTXStd-55Rg.woff') format('woff');
}
