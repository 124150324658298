/* stylelint-disable max-nesting-depth */
.table {
  height: 100%;
  background-color: $color-table-cell-bg;
  font-size: 14px;
  z-index: map-get($z-index, table);

  &__header {
    height: 50px;
    border-top: 1px solid $color-table-border;
    border-bottom: 1px solid $color-table-border;
    background-color: $color-table-header-bg;

    &-cell {
      display: flex;
      align-items: center;
      color: $color-table-header-text;
      font-family: $font-family-medium;

      svg {
        fill: $color-table-header-text;
      }

      button:not(.checkbox__button):not(.checkbox--light__button) {
        display: flex;
        align-items: center;
        padding: 0;
        border: 0;
        background: $color-table-header-bg;
        cursor: pointer;

        &.filter {
          margin-right: 10px;

          svg {
            fill: $color-table-header-text;
          }
        }

        &.sort {
          margin-left: 10px;

          svg {
            .up,
            .down {
              stroke: $color-table-header-text;
              fill: $color-table-header-bg;
            }
          }

          &.asc {
            svg {
              .up {
                stroke: $color-primary-red;
              }
            }
          }

          &.desc {
            svg {
              .down {
                stroke: $color-primary-red;
              }
            }
          }
        }
      }
    }
  }

  &__data {
    height: 40px;
    border-top: 1px solid $color-table-border;
    background-color: $color-table-cell-bg;

    &-cell {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color-table-border;
      color: $color-table-cell-text;
      line-height: 40px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .tooltip__element {
        line-height: 14px;
      }

      .location-icon {
        cursor: pointer;

        &:hover {
          fill: $color-secondary-yellow;
        }
      }
    }
  }

  .shadow {
    box-shadow: 7px 0 10px -5px $color-primary-black;
    z-index: 2;
  }

  .clickable {
    cursor: pointer;
    user-select: none;
  }

  .table-nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $color-primary-white;
    font-family: $font-family-medium;
    font-size: 16px;
  }

  &--light {
    width: 100%;
    border: 1px solid $color-gray-extended-2;
    background-color: $color-table-light-header-bg;
    color: $color-table-light-header-text;

    .shadow {
      box-shadow: 7px 0 10px -5px #AAA;
      z-index: 2;
    }

    .table {
      &__header {
        border: 0;
        border-right: 1px solid $color-gray-extended-2;
        border-bottom: 2px solid $color-table-light-border;
        border-left: 1px solid $color-gray-extended-2;
        background-color: $color-table-light-header-bg;
        font-family: $font-family-medium;

        &-cell {
          color: $color-table-light-cell-text;

          svg {
            fill: $color-table-light-cell-text;
          }

          button:not(.checkbox__button):not(.checkbox--light__button) {
            background: $color-table-light-header-bg;

            &.filter {
              svg {
                fill: $color-table-light-header-text;
              }
            }

            &.sort {
              svg {
                .up,
                .down {
                  stroke: $color-table-light-header-text;
                  fill: $color-table-light-header-bg;
                }
              }

              &.asc {
                svg {
                  .up {
                    stroke: $color-primary-red;
                  }
                }
              }

              &.desc {
                svg {
                  .down {
                    stroke: $color-primary-red;
                  }
                }
              }
            }
          }
        }
      }

      &__data {
        border-top: 1px solid $color-table-light-cell-border;
        border-right: 1px solid $color-gray-extended-2;
        border-bottom: 2px solid $color-primary-black;
        border-left: 1px solid $color-gray-extended-2;
        background-color: $color-table-light-cell-bg;

        &-cell {
          border-bottom: 1px solid $color-table-light-cell-border;
          color: $color-table-light-cell-text;
        }
      }
    }

    &.site-manager {

      .table__data {
        border-top: 0;
        border-bottom: 2px solid $color-gray-extended-7;
      }

      .table__header {
        border-bottom: 2px solid $color-gray-extended-5;
      }
    }
  }

  /* stylelint-disable declaration-no-important */
  &.small-table {
    .table__header-cell,
    .table__data-cell {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  &.auto-height {
    border: 0;
    background: transparent;
  }
}

.users-content {
  .table__data-cell:not(.table__checkbox) {
    display: block;
  }
}

.system-alerts {
  .table__data-cell {
    cursor: pointer;
  }
}

.top-border-light {

  .table__header {
    border-top: 1px solid $color-gray-extended-1;
  }
}
