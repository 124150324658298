.recipient-editor {
  display: flex;
  flex-direction: column;

  &__header {
    display: grid;
    column-gap: 10px;
    grid-column: 1;
    grid-template-columns: 167px 355px 43px;

    .autocomplete {
      margin-left: 5px;
      line-height: 10px;
    }

    .textbox.error {
      margin-bottom: 0;
    }

    .round-button {
      display: flex;
      align-items: center;
      align-self: flex-start;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-top: 26px;
      padding: 0;

      svg {
        width: 14px;
        height: 14px;
        fill: $color-primary-white;
      }
    }
  }

  &__list {
    border-top: 1px solid $color-primary-black;
    border-bottom: 1px solid $color-primary-black;
    background-color: $screen-background-1;
    grid-column: 1;

    .ReactVirtualized__List {
      padding: 10px;
    }

    &-element {
      display: grid;
      column-gap: 15px;
      grid-template-columns: 157px 350px 37px;

      &:last-of-type {
        .textbox {
          margin-bottom: 0;
        }

        input {
          margin-bottom: 0;
        }
      }

      svg {
        width: 18px;
        height: 18px;
        margin-top: 13px;
        margin-left: 5px;
        cursor: pointer;
      }

      .textbox {
        margin-bottom: 5px;

        input {
          margin-bottom: 0;
          background-color: $color-primary-white;
        }
      }

      &--filled {
        svg {
          cursor: pointer;
          fill: $color-primary-black;
        }

        input {
          border-bottom: 1px solid $color-primary-black;
        }
      }

      &--empty {
        svg {
          cursor: default;
          fill: $color-gray-extended-2;
        }

        input {
          border: 1px dashed $color-gray-extended-2;
        }
      }
    }
  }

  &__datasize-text {
    margin-top: 5px;
    margin-left: auto;
    color: $color-gray-extended-7;
    font-family: $font-family-roman;
    font-size: 12px;
    line-height: 14px;
    justify-self: flex-end;
  }
}
