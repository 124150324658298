.table-footer {
  display: flex;
  position: relative;
  align-items: center;
  height: 40px;
  background-color: $color-primary-black;
  color: $color-gray-extended-2;
  font-family: $font-family-roman;
  font-size: 14px;
  line-height: 20px;

  &__item {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 30px;
    white-space: nowrap;

    &:first-child {
      margin-left: 20px;
    }

    &--last {
      margin-left: 0;
    }
  }

  &__text {
    padding-bottom: 1px;
  }

  &__bold {
    max-width: 120px;
    padding-right: 5px;
    color: $color-primary-white;
    font-family: $font-family-medium;
  }

  &__nodes {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 30px;
    border-right: 1px solid $color-gray-extended-5;
  }

  &__groups {
    display: flex;
    align-items: center;
    width: 100%;

    .table-footer__buttons {
      margin: 0 20px 0 auto;
    }

    .table-footer__item {
      margin-left: 30px;
    }
  }

  &__values {
    padding-left: 5px;
    color: $color-secondary-yellow;
    font-family: $font-family-bold;
  }

  &__audit-link {
    display: none;
    position: relative;
    top: 2px;
    left: 18px;
    button:disabled {
      position: relative;
      top: 3px;
      cursor: not-allowed;
      opacity: .7;
    }
  }

  &__audit-link-note {
    font-size: 12px;
    color: #CCC;
    margin-left: 8px;
  }

}
