.container {
  display: flex;
  flex-direction: column;
  min-width: 1366px;
  height: 100vh;
  font-family: $font-base;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    position: relative;
    flex-grow: 1;

    &--admin {
      background-color: $screen-background-1;
    }
  }

  .inner-content {
    position: relative;
    width: 100%;
  }

  .page-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 10px 20px 20px;

    &--large-margin {
      margin: 10px 160px 20px;
    }

    &--medium-margin {
      margin: 20px 30px;
    }

    &--large-bottom-margin {
      margin-bottom: 45px;
    }
  }

  button,
  [role='button'] {
    cursor: pointer;
    user-select: none;
  }
}
