.firmware-page {
  .admin__sidebar-title-section {
    border-bottom: 1px solid $color-gray-extended-7;
  }

  .list-container,
  .toolbar-container.toolbar-container--right {
    width: 1050px;
    margin: auto;
  }

  .ReactVirtualized__Grid__innerScrollContainer,
  .ReactVirtualized__Grid.ReactVirtualized__List {
    margin: auto;
  }
}
