.accounts {
  margin: 20px 0 30px 20px;

  .isDisabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .4;
  }

  .list-container {
    display: block;
    height: 100%;
  }

  &__title {
    margin-right: 20px;
    color: $color-primary-black;
    font-family: $font-family-bold;
    font-size: 24px;
  }

  .react-resizable {
    position: relative;
    /* stylelint-disable declaration-no-important */
    width: 100% !important;
  }

  &__partners {
    display: block;
    height: calc(100vh - 160px);
    overflow: auto;

    .custom-handle,
    .custom-handle-se {
      position: absolute;
      right: -23px;
      bottom: -28px;
      width: 20px;
      height: 20px;
      padding: 0 3px 3px 0;
      background-repeat: no-repeat;
      background-position: bottom right;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: pointer;

      svg {
        fill: $color-gray-extended-2;
      }

      &:hover {
        svg {
          fill: $color-primary-black;
        }
      }
    }
  }

  .partners {
    display: flex;
    margin-top: 15px;
    padding: 10px;
    border: 1px solid $color-primary-black;
    background-color: $color-primary-white;
  }
}

.partner {
  height: 100%;
  margin: 10px 0 25px;
  padding: 20px;
  padding-bottom: 30px;
  border: 1px solid $screen-background-1;
  background-color: $color-primary-white;
  box-shadow: 0 0 5px $color-list-shadow-box;
  overflow: hidden;

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &-name {
      display: flex;
      flex-direction: column;
      font-family: $font-family-bold;

      &-title {
        display: flex;
        align-items: center;
      }

      svg {
        width: 24px;
        margin-right: 10px;
      }
    }
  }

  &__account {
    &-text {
      margin: 5px 0 0 34px;
      color: $color-gray-extended-4;
      font-family: $font-family-roman;
      font-size: 14px;
    }

    &-value {
      margin-right: 5px;
      color: $color-primary-black;
      font-family: $font-family-bold;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    font-size: 10px;

    &-edit {
      width: 50px;
      border: 0;
      outline: 0;
      background: transparent;

      svg {
        height: 18px;
      }
    }

    &.has-scrollbar {
      margin-right: 5px;
    }

    > div:last-child {
      .partner__user:after {
        display: none;
      }
    }
  }

  &__user {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    padding: 0 12px;
    color: $color-secondary-blue-80;
    font-family: $font-family-bold;
    font-size: 18px;

    &:after {
      position: absolute;
      right: 0;
      width: 1px;
      height: 18px;
      background: $color-gray-extended-2;
      content: '';
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }

  .customer-list {
    display: block;
    width: 100%;
    height: 100%;

    button {
      outline: 0;
      background: transparent;
    }

    &__items {
      display: flex;
      height: 50px;
      padding-left: 15px;
      border: 1px solid $color-gray-extended-2;
      font-size: 14px;
      box-shadow: 0 2px 5px $color-list-shadow-box;

      &-main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        height: 100%;
        border-right: 1px solid $color-gray-extended-2;
      }

      &-name {
        font-family: $font-family-medium;
        line-height: 16px;
      }

      &-id {
        color: $color-gray-extended-4;
        font-size: 10px;
        line-height: 12px;
      }

      &-label {
        font-family: $font-family-medium;
        font-size: 12px;
        line-height: 14px;
      }

      &-city {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 15%;
        height: 100%;
        padding-left: 20px;
        border-right: 1px solid $color-gray-extended-2;
        font-size: 14px;
        line-height: 16px;
      }

      &-state {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 15%;
        height: 100%;
        padding-left: 20px;
        border-right: 1px solid $color-gray-extended-2;
      }

      &-sites {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        font-family: $font-family-bold;
        font-size: 18px;

        a {
          color: $color-secondary-blue-80;
        }

        svg {
          height: 18px;
          margin-right: 5px;
        }

        .tooltip {
          font-family: $font-family-roman;
        }
      }

      &-users {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        font-family: $font-family-bold;
        font-size: 18px;

        a {
          color: $color-secondary-blue-80;
        }

        svg {
          width: 18px;
          margin-right: 5px;
        }

        .tooltip {
          font-family: $font-family-roman;
        }
      }

      &-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        border: 0;

        svg {
          height: 18px;
        }
      }

      &-sites,
      &-users {

        &:after {
          position: absolute;
          right: 0;
          width: 1px;
          height: 18px;
          background: $color-gray-extended-2;
          content: '';
        }
      }

      > div:last-child {

        &:after {
          display: none;
        }
      }
    }
  }
}
