.list-options {
  width: 185px;

  &__dropdown-content {
    display: flex;
    flex-direction: column;
  }

  &__element {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 30px;
    border: 0;
    outline: 0;
    background-color: $color-primary-black;
    color: $color-primary-white;
    font-size: 14px;

    &--with-border {
      border-top: 1px solid $color-gray-extended-6;
      border-bottom: 1px solid $color-gray-extended-6;
    }

    > span {
      margin-right: 10px;
    }

    svg {
      width: 15px;
    }

    &:hover {
      color: $color-gray-extended-2;

      svg {
        fill: $color-gray-extended-2;
      }
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    border: 1px solid $color-gray-extended-2;
    background-color: $screen-background-1;
    color: $color-primary-black;
    font-family: $font-family-roman;
    z-index: map-get($z-index, custom-list);

    .checkbox {
      margin: 0 10px;

      &__button {
        background-color: $color-primary-white;

        svg {
          fill: $color-primary-black;
        }
      }
    }
  }

  &__dnd-icon {
    margin-right: 10px;
    margin-left: auto;

    svg {
      height: 14px;
    }
  }

  .dropdown {
    padding: 0;

    &__title {
      margin-bottom: 0;
      padding: 20px 30px 10px;
    }
  }

  .modal {
    &__title {
      margin-bottom: 5px;
    }

    &__content {
      flex-direction: column;
      overflow-y: auto;
    }

    &__close-button {
      svg {
        fill: $color-primary-black;
      }
    }
  }
}
