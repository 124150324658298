.sidebar {
  display: flex;
  flex-direction: column;
  width: 50px;
  min-width: 50px;
  background-color: $color-primary-black;
  color: $color-primary-white;

  > span {
    display: block;
    width: 50%;
    margin: 0 auto;
    border-bottom: 1px solid $color-gray-extended-7;
  }

  &__item {
    height: 50px;
    border: 0;
    border-left: 4px solid transparent;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      border: 0;
      outline: none;
      background-color: $color-primary-black;
      color: $color-primary-white;
      cursor: pointer;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: $color-primary-white;
    }

    .modal {
      svg {
        fill: $color-primary-black;
      }
    }

    .checkbox {
      svg {
        width: 10px;
        height: 10px;
        fill: $color-gray-extended-3;
      }
    }

    &:not(&--open):not(&--disabled):hover {
      svg {
        fill: $color-gray-extended-2;
      }
    }

    &--open {
      border-left: 4px solid $color-primary-red;
      background-color: $color-gray-extended-7;

      .sidebar__item-button {
        background-color: $color-gray-extended-7;

        &.light {
          background-color: $color-gray-extended-6;
        }
      }
    }

    &--disabled {
      svg {
        fill: $color-gray-extended-4;
      }
    }
  }
}
