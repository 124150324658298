.small-data-box {
  display: flex;
  margin-bottom: 10px;
  padding: 10px 10px 0;
  background-color: $color-gray-extended-1;

  &--icon {
    padding-top: 2px;
    padding-right: 10px;
  }

  &--item {
    margin-bottom: 10px;
  }

  &--title {
    font-family: $font-family-medium;
    font-size: 12px;
    line-height: 14px;
  }

  &--text {
    font-family: $font-family-roman;
    font-size: 14px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
