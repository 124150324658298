.fota-campaign-details-modal {
  h2 {
    position: relative;
    width: 500px;
  }

  .refreshFotaCampaignDetails {
    position: absolute;
    top: 34px;
    right: 360px;
    cursor: pointer;
    z-index: 1111;

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% {  transform: rotate(359deg); }
    }

    &.refreshLoading {
      animation: spin 1s ease-in-out infinite;
      cursor: default;
      fill: $color-gray-extended-4;
      pointer-events: none;
    }
  }

  .modal__content {
    flex-direction: column;
  }
}

.fota-campaign-details-header {
  position: absolute;
  display: flex;
  top: 0;
  right: 40px;
  flex-direction: row;
  font-size: 12px;

  > div {
    display: flex;
  }

  &__nodes,
  &__enddate,
  &__status,
  &__nodes-selected {
    width: 100px;
    height: 33px;
    margin: 26px 0;
    padding: 0 12px;
    border-left: 1px solid $color-modal-separator;
    font-family: $font-family-roman;

    &>div:first-child {
      margin-bottom: 6px;
      font-family: $font-family-medium;
      font-size: 12px;
    }
  }

  &__nodes {
    width: 80px;
  }

  &__status {
    width: 120px;
  }

  &__nodes-selected {
    width: 110px;
  }

  &__status span {
    position: relative;
    left: 0;
    margin-left: 0;
    font-size: 12px;
  }

  &__status svg {
    position: relative;
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
}

.fota-campaign-details-container {
  width: 1160px;
  height: 490px;

  .table__data-cell {
    display: inline-block;
  }
}
