.schedule-toolbar {
  .toolbar-tabs__container-tabset-btn {
    width: 230px;
  }
}

.dropdown-schedule-list {
  position: absolute;
  top: 140px;
  right: 0;
  width: 460px;
  padding: 20px 15px;
  background-color: $color-gray-extended-7;
  z-index: map-get($z-index, dropdown);

  .dropdown__content {
    width: 100%;
    height: 100%;
  }

  .dropdown__footer {
    justify-content: flex-end;
  }

  &__content {
    width: 100%;
    height: 100%;

    .modal {
      padding: 30px;
    }
  }
}
