.recently-viewed {
  &__dropdown {
    position: relative;
    right: -58px;
    width: 270px;
    height: calc(100vh - 141px);
    padding: 20px;
    overflow-y: scroll;

    .dropdown__title {
      margin-bottom: 20px;
    }
  }

  &__card {
    margin-bottom: 20px;
    padding: 10px;
    padding-left: 7px;
    border: 1px solid $color-gray-extended-5;
    border-left: 3px solid transparent;
    background-color: $color-gray-extended-8;
    color: $color-gray-extended-3;

    &:hover {
      color: $color-primary-white;
      cursor: pointer;

      svg {
        fill: $color-primary-white;
      }

      .recently-viewed__address {
        color: $color-gray-extended-3;
      }
    }
  }

  &__name {
    margin-bottom: 5px;
    color: $color-primary-white;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__address {
    display: flex;
    font-size: 12px;
    line-height: 14px;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    svg {
      width: 18px;
      height: 18px;
      fill: $color-gray-extended-4;
    }
  }
}

.recently-viewed__card--selected {
  border-left: 3px solid $color-primary-red;
  background-color: $color-gray-extended-6;

  .recently-viewed__icons {
    svg {
      fill: $color-primary-white;
    }
  }
}
