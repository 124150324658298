.tabletoolbar-subtitle {
  display: flex;
  margin-top: 4px;
  font-family: $font-family-roman;
  font-size: 10px;
  line-height: 12px;

  &__bold {
    margin-right: 2px;
    color: $color-secondary-yellow;
    font-family: $font-family-medium;
  }

  &__download {
    margin: 0;
    margin-left: 5px;
    padding: 0;
    border: 0;
    background: transparent;

    svg {
      height: 12px;
      fill: $color-primary-white;
    }
  }
}
