/* stylelint-disable max-nesting-depth */

.energy-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100% - 60px);

  .content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 140px);

    .energy-title {
      display: flex;
      flex-direction: column;
      height: 78px;
      padding-top: 20px;
      padding-right: 30px;
      padding-bottom: 0;
      padding-left: 30px;

      &-button {
        width: 123px;
        border-bottom: 0;
        color: $color-primary-black;
        font-family: $font-family-roman;
        font-size: 12px;
        line-height: 14px;

        &:hover {
          color: $color-gray-extended-4;

          span {
            svg {
              fill: $color-gray-extended-4;
            }
          }
        }

        span {
          display: flex;

          svg {
            align-self: center;
            height: 12px;
            margin-left: 5px;
            fill: $color-primary-black;
          }
        }
      }

      &-text {
        margin-bottom: 5px;
        color: $color-primary-black;
        font-family: $font-family-bold;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .energy-canvas-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 30px;
      padding-top: 0;

      canvas {
        width: 100%;
        height: 50%;
        padding-right: 20px;
      }
    }
  }

  .group-actions {
    position: absolute;
    top: 80px;
    width: 300px;
    z-index: map-get($z-index, dropdown);

    .dropdown {
      .dropdown__footer {
        margin-bottom: 15px;
      }

      .group-actions__container {
        width: 100%;

        &-group {
          svg {
            fill: $color-gray-extended-4;
          }

          &.open {
            svg {
              fill: $color-primary-white;
            }
          }
        }

        &-group-list {
          padding-bottom: 25px;

          span {
            color: $color-gray-extended-3;
            font-size: 14px;
            line-height: 16px;
            white-space: break-spaces;
          }

          .group-list-row {
            display: flex;
            align-items: center;
            padding: 10px;
            border: 1px solid $color-gray-extended-5;
            color: $color-primary-white;
            line-height: 40px;
            cursor: pointer;

            &--selected {
              padding-left: 7px;
              border-left: 4px solid $color-primary-red;
            }

            &__group-name {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            &__node-length {
              margin-left: auto;
              color: $color-secondary-yellow;
            }
          }
        }

        &-footer {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;

          .btn-primary {
            margin-right: 5px;
          }

          .btn-secondary {
            margin-right: 0;
          }
        }
      }
    }
  }

  .react-datepicker__input-container {
    input {
      &::placeholder {
        color: $color-primary-black;
      }

    }
  }

  .toolbar-tabs {
    &__container-title {
      width: 270px;
    }

    &__container-tabset {
      &-container {
        position: relative;
        width: auto;
        min-width: 170px;

        &--wide {
          min-width: 300px;
        }

        &--date {
          min-width: 280px;

          .dropdown-date-period-selector {
            .react-datepicker__label--above {
              color: $color-gray-extended-2;
              font-family: $font-family-bold;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

        &--standalone {
          width: 180px;

          .toolbar-tabs__container-tabset-btn-content {
            &-subtitle {
              color: $color-primary-white;
            }
          }
        }

        &--close {
          min-width: 80px;
          max-width: 80px;

          .toolbar-tabs__container-tabset-btn {
            justify-content: center;
            border-bottom: 0;

            &-content {
              margin: 0;

              svg {
                width: 17px;
                height: 17px;
                margin: 0;
              }
            }
          }
        }

        &--large-font {
          &.wide {
            min-width: 300px;
          }

          .toolbar-tabs__container-tabset-btn {
            &.no-highlight {
              &:hover {
                border-bottom-color: $color-primary-black;
                background-color: $color-primary-black;
                cursor: default;
              }
            }

            &-content {
              cursor: default;

              &-subtitle {
                color: $color-primary-white;
                font-family: $font-family-bold;
                font-size: 12px;
                line-height: 14px;
              }

              &-title {
                color: $color-primary-white;
                font-family: $font-family-bold;
                font-size: 18px;
                line-height: 20px;
              }

              svg {
                width: 18px;
                height: 18px;
                margin: 0 10px 0 0;
                grid-row-start: 1;
                grid-row-end: 2;
              }
            }
          }
        }
      }

      &-btn {
        width: 100%;

        &-content {
          margin-left: 0;

          svg {
            width: 24px;
            height: 24px;
            margin: 0 15px 0 0;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          &-title {
            color: $color-gray-extended-2;
            font-family: $font-family-roman;
            font-size: 14px;
          }

          &-subtitle {
            color: $color-secondary-yellow;
            font-family: $font-family-medium;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.modal--full-screen {
  .energy-panel {
    width: 100%;
    height: 100%;
  }
}
